import React, { useState, useEffect, useRef } from "react";
import { useWeb3 } from "../../Web3Context";
import Web3 from "web3";
import ERC20TokenABI from "../../abis/ERC20TokenABI.json";
import DirectExchangeABI from "../../abis/DirectExchangeABI";
import TokenSwapABI from "../../abis/TokenSwapABI";
import StakeContractAbi from "../../abis/StakeContractAbi.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styled from "@emotion/styled";
import SETTINGS from "../../settings";
import { ERC20_ABI } from "../../abis/erc20";
import loanABI from "../../abis/LoanABI.json";
import StakeholdersABI from "../../abis/StakeholdersABI.json";
import StakeLinkedListABI from "../../abis/StakeLinkedListABI.json";

const ExchangeInput = styled.input`
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
`;

const CalculateButton = styled.button`
  background-color: transparent;
  color: #ffffff !important;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--maincolor);
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;  
  &:hover {
    background-color: var(--maincolor);
  }
`;


const TokenSelect = styled.select`
  padding: 15px; // Increase padding for a larger appearance
  border: 1px solid var(--maincolor);
  border-radius: 100px;
  font-size: 15px; // Increase font size
  appearance: none;
  background-color: transparent;
  width: 100%;
  margin-top: 0.25rem;
  cursor:pointer;
  text-align-last: center; // Center align the text
  text-align: center; // Center align the text in some browsers
`;


const Info = ({ balanceFunction }) => {
  const { web3, selectedAccount } = useWeb3();
  const [swapContractBalance, setSwapContractBalance] = useState(0);
  const [stakeContractBalance, setStakeContractBalance] = useState(0);
  const [loanContractBalance, setLoanContractBalance] = useState(0);
  const [stakeholderContractBalance, setStakeholderContractBalance] = useState(0);
  const [directContractBalance, setDirectContractBalance] = useState(0);
  const [stakeNum, setStakeNum] = useState(0);
  const [allSwapAmount, setAllSwapAmount] = useState(0);
  const [allSwapRewards, setAllSwapRewards] = useState(0);
  const [getLoanUsers, setGetLoanUsers] = useState(0);
  const [stakeholderUsers, setStakeholderUsers] = useState([]);
  const [getLoanNum, setGetLoanNum] = useState(0);
  const [loading, setLoading] = useState(false);
  

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  const getBalances = async () => {
    setLoading(true);
    const bal = await getBalanceGbl(SETTINGS.tokenSwapAddress);
    setSwapContractBalance(numberWithCommas(bal.toFixed(2)));
    const bal1 = await getBalanceGbl(SETTINGS.stakeAddress);
    setStakeContractBalance(numberWithCommas(bal1.toFixed(2)));
    const bal2 = await getBalanceGbl(SETTINGS.loanAddress);
    setLoanContractBalance(numberWithCommas(bal2.toFixed(2)));
    const bal3 = await getBalanceGbl(SETTINGS.directExchangeAddress);
    setDirectContractBalance(numberWithCommas(bal3.toFixed(2)));
    const bal4 = await getBalanceGbl(SETTINGS.stakeholdersAddress);
    setStakeholderContractBalance(numberWithCommas(bal4.toFixed(2)));
    //await getStakeholders(SETTINGS.tokenSwapAddress);
    //await getStakesAll();
    //await getLoansAll();
    setLoading(false);
  };

  const getStakeholders = async (addr) => {
    let stakeUsers = [];
    let stakeUsersTemp = [];
    let totalStaked = 0;
    let unclaimedRewards = 0;
    let unclaimedRewards1 = 0;
    const stakeholdersContract = new web3.eth.Contract(TokenSwapABI, addr);
    console.log("addr");
    console.log(addr);
    try {
      console.log("START getting users");
      for (let j = 0; j <= 10000; j++) {
        console.log(j);
        const user = await stakeholdersContract.methods.stakers(j).call();
        if(SETTINGS.systemAddresses.includes(user)) continue;
        const stakes = await stakeholdersContract.methods.stakes(user).call();
        const rew = await stakeholdersContract.methods.rewards(user).call();
        const rewBnb = await stakeholdersContract.methods.rewardsBnb(user).call();
        totalStaked += parseFloat(stakes) / (10 ** 18);
        unclaimedRewards += parseFloat((parseFloat(rew) / (10 ** 18)).toFixed(4)) + parseFloat((parseFloat(rewBnb) / (10 ** 18)).toFixed(4));
        unclaimedRewards1 += parseFloat((parseFloat(rew) / (10 ** 18)).toFixed(2)) + parseFloat((parseFloat(rewBnb) / (10 ** 18)).toFixed(2));
        console.log(unclaimedRewards);
        stakeUsersTemp.push([user, (parseFloat(stakes) / (10 ** 18)).toFixed(4), (parseFloat(rew) / (10 ** 18)).toFixed(4), (parseFloat(rewBnb) / (10 ** 18)).toFixed(4)]);
        //stakeUsersTemp.push([user, stakes, rew, rewBnb]);
        stakeUsers.push(
          {
            "user": user,
            "stakes": (parseFloat(stakes) / (10 ** 18)).toFixed(4),
            "rew": (parseFloat(rew) / (10 ** 18)).toFixed(4),
            "rewBnb": (parseFloat(rewBnb) / (10 ** 18)).toFixed(4),
          }
        );
      }
    } catch (error) {
      //console.log(error);
      console.log("DONE getting users");
    }
    console.log("unclaimedRewards");
    console.log(unclaimedRewards);
    console.log("unclaimedRewards1");
    console.log(unclaimedRewards1);
    console.log("stakes");
    console.log(JSON.stringify(stakeUsersTemp));
    setStakeholderUsers(stakeUsers);
    console.log(totalStaked);
  }

  const giveLoansAll = async () => {
    let loanUsers = [];
    let csvResult = "address;amount;reward;status\n";
    let tempLoansArr = [];
    const loanContract = new web3.eth.Contract(loanABI, SETTINGS.loanAddress);

    try {
      console.log("START getting users");
      for (let j = 0; j <= 1000; j++) {
        const user = await loanContract.methods.giveLoanUsers(j).call();
        loanUsers.push(user);
      }
    } catch (error) {
      //console.log(error);
      console.log("DONE getting users");
    }
    try {
      console.log("START getting loans");
      for (let i = 0; i <= loanUsers.length; i++) {
        const loansCount = await loanContract.methods
          .positionGiveLoansCount(loanUsers[i])
          .call();
        for (let j = 0; j < loansCount; j++) {
          const userLoans = await loanContract.methods
            .positionGiveLoans(loanUsers[i], j)
            .call();
    
          tempLoansArr.push([loanUsers[i], userLoans.amount, userLoans.reward, userLoans.active, userLoans.createdAt]);
          let amount = parseFloat(userLoans.amount) / (1 * 10 ** 18);
          let reward = parseFloat(userLoans.reward) / (1 * 10 ** 18);
          let status = "ACTIVE";
          if (reward >= amount * 2) {
            status = "FINISHED";
          }
          if (reward == 0) {
            status = "CLOSED";
          }
          let csvRow =
            loanUsers[i] +
            ";" +
            amount.toFixed(2) +
            ";" +
            reward.toFixed(2) +
            ";" +
            status +
            "\n";
          csvResult += csvRow;
        }
      }
    } catch (error) {
      //console.log(error);
      console.log("DONE getting loans");
    }
   
  };

  const getLoansAll = async () => {
    let loanUsers = [];
    let loanCount = 0;
    let fullRewards = 0;

    const loanContract = new web3.eth.Contract(loanABI, SETTINGS.loanAddress);

    try {
      console.log("START getting users");
      for (let j = 0; j <= 10000; j++) {
        console.log(j);
 
        const user = await loanContract.methods.stakers(j).call();
        
        if(SETTINGS.systemAddresses.includes(user)){
          //console.log("skipping system");
          continue;
        }
        const reward = await loanContract.methods.stakeRewards(user).call();
        const stakePowers = await loanContract.methods.stakePowers(user).call();
        ;
        
      
        let dRew = parseFloat(web3.utils.fromWei(reward, 'ether')).toFixed(5);
        if(parseFloat(dRew) < 0.01){
          dRew = 0;
        }
        fullRewards += parseFloat(dRew);
        loanUsers.push(
        {
          "user": user,
         
          "rew": dRew,
          "stakePowers": (parseFloat(stakePowers) / (10 ** 18)).toFixed(4),
        });
      }
    } catch (error) {
      //console.log(error);
      console.log("DONE getting users");
    }
    console.log(JSON.stringify(loanUsers));
  
  };
  const btnDistributeClick = async () => {
    await getStakesAll();
  };


  const getStakesAll = async () => {
    
    let arraySwaps = [];
    let csvResult = "";
    let stakeNum = 0;
    let swapAmountCount = 0.0;
    let swapRewardCount = 0.0;

  
    const llContract = new web3.eth.Contract(
      StakeLinkedListABI,
      SETTINGS.stakeLinkedListAddress
    );

    const currUID = await llContract.methods.currentUID().call();
    

    try {
      for (let j = 1; j <= currUID; j++) {
        console.log(j);

        const stake = await llContract.methods.linkedList(j).call();
        csvResult+= stake.staker+";";
        
      }
    } catch (error) {
      console.log(error);
    }
    console.log(csvResult);
    
  };


  const getBalanceGbl = async (address) => {
    if (!Web3.utils.isAddress(address)) {
      //alert("Please enter a valid contract address.");
      return 0;
    }

    const tokenContractGbl = new web3.eth.Contract(
      ERC20_ABI,
      SETTINGS.globalTokenAddress
    );

    try {
      const balance = await tokenContractGbl.methods
        .balanceOf(address)
        .call();
     
      const formattedBalance = balance / 10 ** 18;
      return formattedBalance;
    } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
    }
    return 0;
  };

 
  useEffect(() => {
    if (selectedAccount) {
        
      getBalances();
    }
  }, [selectedAccount]);







  

  return (
    <>
    <div className="boxTitle">{loading ? (<FontAwesomeIcon icon={faSpinner} spin color="var(--maincolor)"  />) : (null)} Info </div>
    <div className="row">
    
        <div className="col-6">
            <div className="card" >
            <CalculateButton
              className="large-button-blue"
              onClick={btnDistributeClick}
            >
              Distribute
            </CalculateButton>
                <div className="card-header">
                    <b>Balances</b>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <small className="smallText">Token Swap Contract</small><br />
                        {swapContractBalance} <small>GBL</small>
                    </li>
                    <li className="list-group-item">
                        <small className="smallText">User Swaps Contract</small><br />
                        {stakeContractBalance} <small>GBL</small>
                    </li>
                    <li className="list-group-item">
                        <small className="smallText">Loan Contract</small><br />
                        {loanContractBalance} <small>GBL</small>
                    </li>
                    <li className="list-group-item">
                        <small className="smallText">Stake Contract</small><br />
                        {stakeholderContractBalance} <small>GBL</small>
                    </li>
                    <li className="list-group-item">
                        <small className="smallText">Direct Exchange Contract</small><br />
                        {directContractBalance} <small>GBL</small>
                    </li>
                    
                </ul>
            </div>
        </div>
        <div className="col-6">
            <div className="card" >
                <div className="card-header">
                    <b>Ecosystem info</b>
                </div>
                <ul className="list-group list-group-flush">
                <li className="list-group-item">
                        <small className="smallText">Number of Swaps</small><br />
                        {stakeNum} <small>Stakes</small>
                </li>
                <li className="list-group-item">
                        <small className="smallText">All Swaps amount</small><br />
                        {allSwapAmount} <small>GBL</small>
                </li>
                <li className="list-group-item">
                        <small className="smallText">All Swaps Rewards</small><br />
                        {allSwapRewards} <small>GBL</small>
                </li>
                <li className="list-group-item">
                        <small className="smallText">Number of Loan Users</small><br />
                        {getLoanUsers} <small>Users</small>
                </li>
                <li className="list-group-item">      
                        <small className="smallText">Number of Loans</small><br />
                        {getLoanNum} <small>Loans</small>
                </li>
                </ul>
            </div>
        </div>
        </div>  
        <br />
        <div className="row">
        <div className="col-6">
            <div className="card" >
                <div className="card-header">
                    <b>Contracts</b>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <small>Token Swap Contract</small><br />
                        <small className="smallText">{SETTINGS.tokenSwapAddress}</small>
                    </li>
                    <li className="list-group-item">
                        <small>Stake Contract</small><br />
                        <small className="smallText">{SETTINGS.stakeAddress}</small>
                    </li>
                    <li className="list-group-item">
                        <small>Loan Contract</small><br />
                        <small className="smallText">{SETTINGS.loanAddress}</small>
                    </li>
                    <li className="list-group-item">
                        <small>Direct Exchange Contract</small><br />
                        <small className="smallText">{SETTINGS.directExchangeAddress}</small>
                    </li>
                    <li className="list-group-item">
                        <small>Game Contract</small><br />
                        <small className="smallText">{SETTINGS.lotteryAddress}</small>
                    </li>
                </ul>
            </div>
        </div>
        <div className="col-6">
            <div className="card" >
                <div className="card-header">
                    <b>Stakeholders</b>
                </div>
                <div className="card-body">
                <small className="smallText">Total:</small><br />
                        {stakeholderContractBalance} <small>GBL</small>
                        <hr />
                <table className="table">
                {stakeholderUsers.map((user, index) => (
                  <tr key={index} >
                  
                    <td style={{ fontSize: "8px" }}>{user.user}</td>
                    <td style={{ fontSize: "10px" }}>{user.stakes}</td>
                    <td style={{ fontSize: "10px" }}>{user.rew}</td>
                    <td style={{ fontSize: "10px" }}>{user.rewBnb}</td>
                   
                    
                    
                  </tr>
                ))}
                </table>
            </div>
            </div>
        </div>
    </div>
    
    </>
  );
};

export default Info;
