import React, { useState, useEffect, useContext } from 'react';
import { useWeb3 } from "../Web3Context";
import Web3 from 'web3';

import SETTINGS from "../settings";
import WaweSwapsABI from "../abis/WaweSwapsV3ABI";
import WaweSwapStorageABI from "../abis/WaweSwapStorageV3ABI";
import { ERC20_ABI } from "../abis/erc20";
import styled from "@emotion/styled";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { parse } from '@fortawesome/fontawesome-svg-core';
import Dropdown from 'react-bootstrap/Dropdown';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingDots from "./LoadingDots";
import {faCaretDown,faCaretUp} from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird, faArrowsDownToLine, faCircleInfo } from '@fortawesome/pro-thin-svg-icons'
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ToastNotification from './ToastNotification';
import InfoModal from './InfoModal';
import TooltipInfoReverse from './TooltipInfoReverse';
import AdminPanelV3 from './AdminPanelV3';

const SpinnerBox = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: var(--maincolor);
  font-weight: bold;
  font-size: 20px;
  border: 0px solid var(--maincolor);
  padding: 20px;
  border-radius:100px;
  cursor: pointer;
  width:100%;
  transition: all 0.2s;
  text-align:center;
`;

const ClaimButton = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: #999999;
  font-weight: bold;
  font-size: 10px;
  border: 1px solid #999999;
  padding: 5px 20px;
  border-radius:100px;
  cursor: pointer;
  margin-left:0px;
 margin-top:12px;
  transition: all 0.2s;
  text-align:center;
  &:hover {
    
    border: 1px solid var(--maincolor);
    color: var(--maincolor);
    transition: all 0.6s;
  }
`;

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);
  const ACTIVE = 'accordionActive';
  const CLOSED = 'accordionClosed';
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className={isCurrentEventKey ? ACTIVE : CLOSED }
      onClick={decoratedOnClick}
    >
     <FontAwesomeIcon icon={isCurrentEventKey ? faCaretUp : faCaretDown } style={{fontSize:15}}  /> {children}
    </button>
  );
}


function WaweSwap() {
    const { web3, selectedAccount, networkId, networkSymbol, networkNames, waweSwapV3Addresses, waweSwapStorageV3Addresses, waweSwapV3BuyTokens, networkIcons } = useWeb3();
    const [isLoading, setIsLoading] = useState(false);
    const [swapAvailable, setSwapAvailable] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [ercBalance, setErcBalance] = useState(0);
    const [ercAlowance, setErcAlowance] = useState('');
    const [gblBalance, setGblBalance] = useState(0);
    const [swapV3Balance, setSwapV3Balance] = useState("");
    const [unclaimedRewards, setUnclaimedRewards] = useState(0);
    const [unclaimedRewardsNet, setUnclaimedRewardsNet] = useState(0);
    const [unclaimedRewardsFee, setUnclaimedRewardsFee] = useState(0);
    const [swapTokenAmount, setSwapTokenAmount] = useState('');
    const [gblBuyAmount, setGblBuyAmount] = useState('');
    const [usdtBuyValue, setUsdtBuyValue] = useState('');
    const [usdtSellAmount, setUsdtSellAmount] = useState('');
    const [priceImpact, setPriceImpact] = useState(0);
    const [buyTokens, setBuyTokens] = useState([]);
    const [userSwaps, setUserSwaps] = useState([]);
    const [selectedToken, setSelectedToken] = useState(null);
   
    const [selectDisabled, setSelectDisabled] = useState(false);
    const [showCollectModal, setShowCollectModal] = useState(false);
    const [loadingText, setLoadingText] = useState("Loading");
    const [loadingStep, setLoadingStep] = useState(1);
    const [loadingNumber, setLoadingNumber] = useState(1);
    const [selectedLabel, setSelectedLabel] = useState("Select");
 
const [showToast, setShowToast] = useState(false);
const [toastUrl, setToastUrl] = useState(null);
const [toastError, setToastError] = useState(false);
const [toastMessage, setToastMessage] = useState("-");



const displayToast = (msg, url = null,  error = false) => {
  setToastMessage(msg);
  setToastUrl(url);
  setToastError(error);
  setShowToast(true);
};

    const handleSelect = (token) => {
      setSelectedLabel(`${token.symbol}`);
      handleTokenChange({ target: { value: token.address } });
    };
  
    
      const handleCloseCollectModal = () => {
        setShowCollectModal(false);
      };
      const handleShowCollectModal = () => {
        setShowCollectModal(true);
      };

 
    
    

      const getBalance = async () => {
        if (!Web3.utils.isAddress(selectedAccount)) {
          alert("Please enter a valid Ethereum address.");
          return;
        }
    
        try {
          const balanceWei = await web3.eth.getBalance(selectedAccount);
          const balanceEth = web3.utils.fromWei(balanceWei, "ether");
          return parseFloat(balanceEth).toFixed(6);
        } catch (error) {
          console.error("Error fetching ETH balance:", error);
        }
      };


      const getBalanceERC = async (addr, user) => {
        if (!Web3.utils.isAddress(addr)) {
          //alert("Please enter a valid contract address.");
          return;
        }
    
        const tokenContract = new web3.eth.Contract(ERC20_ABI, addr);
    
        try {
          const balance = await tokenContract.methods
            .balanceOf(user)
            .call();
          const decimals = await tokenContract.methods.decimals().call();
          
          const formattedBalance = balance / 10 ** decimals;
          return formattedBalance.toFixed(6);
        } catch (error) {
          console.error("Error fetching token balance:", error);
          return 0;
        }
      };
      
    

    useEffect(() => {
        if (selectedAccount) {
        
            firstLoad();
            
            
        }else{
          setGblBalance(0);
          setSwapV3Balance(0);
          setUserSwaps([]);
            if(buyTokens.length == 0){
              showAvailableTokens();
              
          }
        }
        console.log(networkId);
        handleSelect({"symbol": "USDT", "address": SETTINGS.usdtAddress[networkId]});
    }, [selectedAccount, buyTokens]);

      const firstLoad = async () => {
        await loadUserData();
        if(buyTokens.length == 0){
            showAvailableTokens();
            
        }else{
          
        }
        
      }
      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      const loadUserData = async () => {
           
      
                setGblBalance(await getBalanceERC(SETTINGS.gblAddresses[networkId], selectedAccount));
                const contractBal = parseFloat(await getBalanceERC(SETTINGS.gblAddresses[networkId], waweSwapV3Addresses[networkId])).toFixed(2);
                setSwapV3Balance(numberWithCommas(contractBal));
                
                const contract = new web3.eth.Contract(WaweSwapsABI, waweSwapV3Addresses[networkId]);
             
             
                const swaps = await getSwapsForUser(selectedAccount);
          
               
                setUserSwaps(swaps);
                
             
                
                setIsLoading(false);
            
        };

        const getTimeLeft =  (createdAt) => {
 // Convert createdAt to milliseconds for the start time and add 30 days for the end time
 const endTime = new Date((createdAt + 30 * 24 * 60 * 60) * 1000);

 // Current time
 const now = new Date();

 // Calculate the difference in milliseconds
 const timeLeft = endTime - now;

 // Convert time left into days and hours
 const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
 const hoursLeft = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

 // Return a string with days and hours left
 return `${daysLeft} days and ${hoursLeft} hours left`;

        };


        const getSwapsForUser = async (userAddress) => {
          try {
              const contract = new web3.eth.Contract(WaweSwapsABI, waweSwapV3Addresses[networkId]);
              const contractStorage = new web3.eth.Contract(WaweSwapStorageABI, waweSwapStorageV3Addresses[networkId]);
             
     
                 // Assuming you know the decimals here; if not, fetch dynamically from the contract
                 let tokenDecimals = 6; // Defaulting to 18 for demonstration; adjust as needed
                 if(networkId == 56){
                  tokenDecimals = 18;
                 }
              const userSwaps = await contractStorage.methods.getSwaps(userAddress).call();
              const userRewardAmountWei = await contractStorage.methods.usdtRewards(userAddress).call();
                let swaps = [];
                
                const userRewardAmount = parseFloat(web3.utils.fromWei(userRewardAmountWei, tokenDecimals === 18 ? 'ether' : 'mwei'));
              for (let i = 0; i < userSwaps.length; i++) {
           
       

                  const amount = parseFloat(web3.utils.fromWei(userSwaps[i][0], 'ether'));
                  const startValue = parseFloat(web3.utils.fromWei(userSwaps[i][2], tokenDecimals === 18 ? 'ether' : 'mwei'));
                  const sellAmount = parseFloat(web3.utils.fromWei(userSwaps[i][1], tokenDecimals === 18 ? 'ether' : 'mwei'));
                 

    

                const maxSellAmount = startValue;
                let soldAmount = maxSellAmount - sellAmount;
                  if(soldAmount < 0) soldAmount = 0;
                  // Adjust the conversion based on the token's decimals
                  swaps.push({
                      uid: i,
                      amount: amount.toFixed(2),
                      startValue: startValue.toFixed(2), 
                      sellAmount: sellAmount.toFixed(2), 
                      maxSellAmount: maxSellAmount.toFixed(2), 
                      soldAmount: soldAmount.toFixed(2), 
                      timeLeft: getTimeLeft(parseInt(userSwaps[i][4]))
                  });
                
              }
             
            
      
              setUnclaimedRewards(userRewardAmount.toFixed(4));
          

              let feePercentage;
              if(userRewardAmount < 100) {
                  feePercentage = 0.02; // 2% for less than 100 ether
              } else if(userRewardAmount >= 100 && userRewardAmount < 1000) {
                  feePercentage = 0.01; // 1% for 100 to 1000 ether
              } else {
                  feePercentage = 0.005; // 0.5% for 1000 ether or more
              }

              let feeAmount = userRewardAmount * feePercentage;
              let netRewardAmount = userRewardAmount - feeAmount;
              setUnclaimedRewardsFee(feeAmount.toFixed(4));
              setUnclaimedRewardsNet(netRewardAmount.toFixed(4));

              return swaps.reverse();
            } catch (error) {
              console.error('Error fetching swap data:', error);
            }
            return [];
          };

    const handleTokenChange = async (e) => {
        const selectedAddress = e.target.value;
        if (selectedAddress === "") {
          setSelectedToken(null);
          setSelectDisabled(false);
        } else {
          const token = buyTokens.find((token) => token.address === selectedAddress);
          if(token == undefined) return;
          setSelectedToken(token);
          if(token.symbol === networkSymbol){
            setErcBalance(await getBalance());
            setErcAlowance("-");
          }else{
            setErcBalance(await getBalanceERC(token.address, selectedAccount));
            const ercContract = new web3.eth.Contract(ERC20_ABI, token.address);
            const allowanceERC = await ercContract.methods.allowance(selectedAccount, waweSwapV3Addresses[networkId]).call();
          const decimals = await ercContract.methods.decimals().call();
          
          const formattedBalance = parseFloat(allowanceERC) / 10 ** decimals;
            setErcAlowance(formattedBalance.toFixed(4));
          }
          setSwapTokenAmount("");
          setGblBuyAmount(0);
          setUsdtSellAmount(0);
          setUsdtBuyValue(0);
          setSelectDisabled(false);
          
        }
        setSwapAvailable(false);
      };
      
      const getPriceNetwork = async (tokenAddress) => {

        const routerABI = [
          {
            "constant": true,
            "inputs": [
              {
                "internalType": "uint256",
                "name": "amountIn",
                "type": "uint256"
              },
              {
                "internalType": "address[]",
                "name": "path",
                "type": "address[]"
              }
            ],
            "name": "getAmountsOut",
            "outputs": [
              {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
              }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
          }
        ];
        
       
        const usdtAddress = SETTINGS.usdtAddress[networkId];

        const routerContract = new web3.eth.Contract(routerABI, "0x10ED43C718714eb63d5aA57B78B54704E256024E");
    
    
        const amountIn = web3.utils.toWei('1', 'ether'); // This is for tokens with 18 decimals
    
      
    
        try {
            const amountsOut = await routerContract.methods.getAmountsOut(amountIn, [tokenAddress, usdtAddress]).call();
            
            
            let priceInUSDT = amountsOut[1];
            
            return priceInUSDT;
        } catch (error) {
            console.error('Error fetching price:', error);
            return 999;
        }
    };


      const getUniswapV3PriceUSDT = async (tokenAddress, poolFee) => {

        if(networkId == 56) {
          const p = await getPriceNetwork(tokenAddress);
        
          return p;
        }
        const usdtAddress = SETTINGS.usdtAddress[networkId];
        
        const quoterABI = [
            {
              "inputs": [
                {
                  "internalType": "address",
                  "name": "tokenIn",
                  "type": "address"
                },
                {
                  "internalType": "address",
                  "name": "tokenOut",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "fee",
                  "type": "uint24"
                },
                {
                  "internalType": "uint256",
                  "name": "amountIn",
                  "type": "uint256"
                },
                {
                  "internalType": "uint160",
                  "name": "sqrtPriceLimitX96",
                  "type": "uint160"
                }
              ],
              "name": "quoteExactInputSingle",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "amountOut",
                  "type": "uint256"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            }
          ]; // Uniswap V3 Quoter ABI
        const quoterContract = new web3.eth.Contract(quoterABI, SETTINGS.quoterAddress[networkId]);
    
        const amountIn = web3.utils.toWei('1', 'ether'); // Assuming the input token has 18 decimals
    
        try {
            // Assuming quoteExactInputSingle is the correct method; adjust as necessary
            const quote = await quoterContract.methods.quoteExactInputSingle(tokenAddress, usdtAddress, poolFee, amountIn, 0).call();
    
    
            // Return the price as a string with up to 6 decimal places
            return quote;
        } catch (error) {
            console.error('Error fetching price:', error);
            return "9999999999"; // Indicative of an error state, adjust as necessary
        }
    };

    const handleBuyAmountChange = async (e) => {
        const amount = e.target.value;
        setSwapTokenAmount(amount);
          setGblBuyAmount(0);
          setUsdtSellAmount(0);
          setUsdtBuyValue(0);
        if (amount === "") {
          setSwapAvailable(false);
        } else {
            if(parseFloat(amount) > parseFloat(ercBalance)){
                setErrorText("Balance to low!");
                setGblBuyAmount(0);
                setUsdtSellAmount(0);
                setUsdtBuyValue(0);
                setSwapAvailable(false);
            }else{
                setErrorText("");
                let dec = 6;
                if(networkId == 56){
                  dec = 18
                }
                let tokenPriceUSDT = 1 * Math.pow(10, dec);
                if(selectedToken.symbol === "USDT"){}else{
                  tokenPriceUSDT = await getUniswapV3PriceUSDT(selectedToken.address, 10000);
                }
                
                const gblPriceUSDT = await getUniswapV3PriceUSDT(SETTINGS.gblAddresses[networkId], 10000);
                
                const tokenPriceUSDTDecimal = parseFloat(tokenPriceUSDT) / Math.pow(10, dec);
                const gblPriceUSDTDecimal = parseFloat(gblPriceUSDT) / Math.pow(10, dec);
                const amountInUSDT = parseFloat(amount) * tokenPriceUSDTDecimal;

                const amountGblToBuy = amountInUSDT / gblPriceUSDTDecimal;
                
                let feePercentage = 1.1;
                
                if(parseFloat(amountInUSDT) < 100) {
                    feePercentage = 1.5; 
                } else if(parseFloat(amountInUSDT) >= 100 && parseFloat(amountInUSDT) < 250) {
                  feePercentage = 1.4; 
                } else if(parseFloat(amountInUSDT) >= 250 && parseFloat(amountInUSDT) < 750) {
                  feePercentage = 1.3; 
                } else if(parseFloat(amountInUSDT) >= 750 && parseFloat(amountInUSDT) < 1000) {
                  feePercentage = 1.2; 
                } else if(parseFloat(amountInUSDT) >= 1000) {
                  feePercentage = 1.1; 
                } 
                setPriceImpact(((feePercentage - 1) * 100).toFixed(0));
                setGblBuyAmount(parseFloat(amountGblToBuy).toFixed(6));
                setUsdtBuyValue(parseFloat(amountInUSDT).toFixed(6));
                setUsdtSellAmount((parseFloat(amountInUSDT) * feePercentage).toFixed(6));
                
                setSwapAvailable(true);
            }
        
        }
        
      };

    const collectIncome = async () => {
    

        setIsLoading(true);
        
        
            try {
                const contract = new web3.eth.Contract(WaweSwapsABI, waweSwapV3Addresses[networkId]);
            const tx = await contract.methods.claimUsdtReward().send({
                from: selectedAccount,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
              });
              loadUserData();
        handleCloseCollectModal();
        
        setIsLoading(false);
        displayToast('Income claimed.', tx.transactionHash);
          
        } catch (error) {
            console.error('stake failed', error);
            
        
        }
        
    }



    const handleSwap = async () => {
        setIsLoading(true);
        if(networkId != 56){
          await handleSwapOthers();
          return
        }
        try {
          const contract = new web3.eth.Contract(
            WaweSwapsABI, 
            waweSwapV3Addresses[networkId]);
          const fee =  await contract.methods.FEE().call();
            if(selectedToken.symbol === networkSymbol) {
                setLoadingNumber(1);
     
              
    
                // Calculate the BNB amount in Wei
                const swapAmountWithFee = parseFloat(swapTokenAmount) + parseFloat(web3.utils.fromWei(fee.toString(), 'ether'));
                const bnbAmountInWei = web3.utils.toWei(swapAmountWithFee.toString(), 'ether');
                setLoadingStep(1);
                
                setLoadingText("Opening WaweSwap V3"); 

                const gblAmountSlippage = parseFloat(gblBuyAmount) - (parseFloat(gblBuyAmount) / 100) * 50;
                const gblAmountInWei = web3.utils.toWei(gblBuyAmount.toString(), 'ether');
                const gblAmountSlippageInWei = web3.utils.toWei(gblAmountSlippage.toString(), 'ether');
                
                const buyValueSlippage = parseFloat(usdtBuyValue) - (parseFloat(usdtBuyValue) / 100) * 1;
                const buyValueInWei = web3.utils.toWei(buyValueSlippage.toString(), 'ether');
                const sellAmountInWei = web3.utils.toWei(usdtSellAmount.toString(), 'ether');
        

            
               

                const tx =  await contract.methods.buySwapWithETH(
                  gblAmountInWei, buyValueInWei, gblAmountSlippageInWei
                ).send({ 
                    from: selectedAccount,
                    value: bnbAmountInWei, // Send BNB along with the transaction
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null  
                });
                loadUserData();
                setSwapTokenAmount('');
                setGblBuyAmount(0);
                setUsdtSellAmount(0);
                  setUsdtBuyValue(0);
                  setIsLoading(false);
                  displayToast('Position Opened!', tx.transactionHash);
    
            } else {
                setLoadingNumber(2);

                setLoadingStep(1);
                setLoadingText("Approving " + selectedToken.symbol + " transaction.");
    
                  // Approve the contract to spend tokens on behalf of the user
                  const tokenContract = new web3.eth.Contract(ERC20_ABI, selectedToken.address);
                
                  const tokenDecimals = await tokenContract.methods.decimals().call();
                  const amountInTokenUnits = swapTokenAmount; // The token amount the user wants to swap
                  const decimals = tokenDecimals; // Number of decimals for the token
                  
                  // Convert amount to Wei equivalent based on token decimals
                  let dec = 6;
                  if(networkId == 56){
                    dec = 18
                  }
                  const amountInWei = (parseFloat(amountInTokenUnits) * Math.pow(10, dec)).toFixed(0);
                  //const amountInWei = new web3.utils.BN(amountInTokenUnits).mul(new web3.utils.BN(10).pow(new web3.utils.BN(decimals)));
                  try{
                    const allowanceToken = await tokenContract.methods.allowance(selectedAccount, waweSwapV3Addresses[networkId]).call();
                    if(parseFloat(allowanceToken) < parseFloat(amountInWei)){
                      await tokenContract.methods.approve(waweSwapV3Addresses[networkId], amountInWei).send({ 
                          from: selectedAccount,
                          maxPriorityFeePerGas: null,
                          maxFeePerGas: null  });
                      }

                      try{

                        setLoadingStep(2);
                     
                        setLoadingText("Opening WaweSwap V3");
                        // Perform the swap for ERC20
                        const gblAmountSlippage = parseFloat(gblBuyAmount) - (parseFloat(gblBuyAmount) / 100) * 50;
                        const gblAmountInWei = web3.utils.toWei(gblBuyAmount.toString(), 'ether');
                        const gblAmountSlippageInWei = web3.utils.toWei(gblAmountSlippage.toString(), 'ether');

                        const buyValueSlippage = parseFloat(usdtBuyValue) - (parseFloat(usdtBuyValue) / 100) * 1;
                        const buyValueInWei = web3.utils.toWei(buyValueSlippage.toString(), 'ether');
                        const sellAmountInWei = web3.utils.toWei(usdtSellAmount.toString(), 'ether');
                         
                    
                        const tx = await contract.methods.buySwap(
                            selectedToken.address, 
                            amountInWei,
                            gblAmountInWei,
                            buyValueInWei,
                            gblAmountSlippageInWei
                        ).send({ 
                            from: selectedAccount,
                            value: parseFloat(fee),
                            maxPriorityFeePerGas: null,
                            maxFeePerGas: null  });
                        
                            loadUserData();
          
            setSwapTokenAmount('');
          setGblBuyAmount(0);
          setUsdtSellAmount(0);
          setUsdtBuyValue(0);
            setIsLoading(false);
            displayToast('Position Opened!', tx.transactionHash);
                      }catch (error){
                        console.error("Send failed");
                        console.log(error);
                        displayToast(error.message, null, true);
                      }

                  }catch (error){
                    console.error("Approval failed");
                    console.log(error);
                    displayToast(error.message, null, true);
                  }
                      

           
            }
    
        } catch (error) {
            console.error('Swap failed', error);
            displayToast(error.message, null, true);
            
        } finally {
            // Ensure user data is reloaded and UI is updated accordingly
            
        }
        setSwapAvailable(false);
        setIsLoading(false);
    };
    const handleSwapOthers = async () => {
        setIsLoading(true);
    
        try {
          const contract = new web3.eth.Contract(
            WaweSwapsABI, 
            waweSwapV3Addresses[networkId]);
            const fee =  await contract.methods.FEE().call();
            if(selectedToken.symbol === networkSymbol) {
                setLoadingNumber(1);
     
              
    
                // Calculate the BNB amount in Wei
                const swapAmountWithFee = parseFloat(swapTokenAmount) + parseFloat(web3.utils.fromWei(fee.toString(), 'ether'));
                const bnbAmountInWei = web3.utils.toWei(swapAmountWithFee.toString(), 'ether');
                
                setLoadingStep(1);
                
                setLoadingText("Opening WaweSwap V3");

                const gblAmountSlippage = parseFloat(gblBuyAmount) - (parseFloat(gblBuyAmount) / 100) * 50;
                const gblAmountInWei = web3.utils.toWei(gblBuyAmount.toString(), 'ether');
                const gblAmountSlippageInWei = web3.utils.toWei(gblAmountSlippage.toString(), 'ether');
                
                const buyValueSlippage = parseFloat(usdtBuyValue) - (parseFloat(usdtBuyValue) / 100) * 1;
                const buyValueInWei1 = web3.utils.toWei(buyValueSlippage.toString(), 'ether');
                const sellAmountInWei = web3.utils.toWei(usdtSellAmount.toString(), 'ether');
                const buyValueInWei = (parseFloat(buyValueSlippage) * Math.pow(10, 6)).toFixed(0);

              
                const tx =  await contract.methods.buySwapWithETH(gblAmountInWei, buyValueInWei, gblAmountSlippageInWei
                ).send({ 
                    from: selectedAccount,
                    value: bnbAmountInWei, // Send BNB along with the transaction
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null  
                });
                loadUserData();
                setSwapTokenAmount('');
                setGblBuyAmount(0);
                setUsdtSellAmount(0);
                  setUsdtBuyValue(0);
                  setIsLoading(false);
                  displayToast('Position Opened!', tx.transactionHash);
    
            } else {
                setLoadingNumber(2);

                setLoadingStep(1);
                setLoadingText("Approving " + selectedToken.symbol + " transaction.");
    
                  // Approve the contract to spend tokens on behalf of the user
                  const tokenContract = new web3.eth.Contract(ERC20_ABI, selectedToken.address);
                
                  const tokenDecimals = await tokenContract.methods.decimals().call();
                  const amountInTokenUnits = swapTokenAmount; // The token amount the user wants to swap
                 
                  const amountInWei = (parseFloat(amountInTokenUnits) * Math.pow(10, tokenDecimals)).toFixed(0);
                  
                  try{
                    const allowanceToken = await tokenContract.methods.allowance(selectedAccount, waweSwapV3Addresses[networkId]).call();
                    if(parseFloat(allowanceToken) < parseFloat(amountInWei)){
                      await tokenContract.methods.approve(waweSwapV3Addresses[networkId], amountInWei).send({ 
                          from: selectedAccount,
                          maxPriorityFeePerGas: null,
                          maxFeePerGas: null  });
                      }

                      try{

                        setLoadingStep(2);
                     
                        setLoadingText("Opening WaweSwap V3");
                        // Perform the swap for ERC20
                        const gblAmountSlippage = parseFloat(gblBuyAmount) - (parseFloat(gblBuyAmount) / 100) * 50;
                        const gblAmountInWei = web3.utils.toWei(gblBuyAmount.toString(), 'ether');
                        const gblAmountSlippageInWei = web3.utils.toWei(gblAmountSlippage.toString(), 'ether');

                        const buyValueSlippage = parseFloat(usdtBuyValue) - (parseFloat(usdtBuyValue) / 100) * 1;
                const buyValueInWei1 = web3.utils.toWei(buyValueSlippage.toString(), 'ether');
                const sellAmountInWei = web3.utils.toWei(usdtSellAmount.toString(), 'ether');
                const buyValueInWei = (parseFloat(buyValueSlippage) * Math.pow(10, 6)).toFixed(0);

           
                      
                        const tx = await contract.methods.buySwap(
                            selectedToken.address, 
                            amountInWei,
                            gblAmountInWei,
                            buyValueInWei,
                            gblAmountSlippageInWei
                        ).send({ 
                            from: selectedAccount,
                            value: parseFloat(fee),
                            maxPriorityFeePerGas: null,
                            maxFeePerGas: null  });
                        
                            loadUserData();
          
            setSwapTokenAmount('');
          setGblBuyAmount(0);
          setUsdtSellAmount(0);
          setUsdtBuyValue(0);
            setIsLoading(false);
            displayToast('Position Opened!', tx.transactionHash);
                      }catch (error){
                        console.error("Send failed");
                        console.log(error);
                        displayToast(error.message, null, true);
                      }

                  }catch (error){
                    console.error("Approval failed");
                    console.log(error);
                    displayToast(error.message, null, true);
                  }
                      

           
            }
    
        } catch (error) {
            console.error('Swap failed', error);
            displayToast(error.message, null, true);
            
        } finally {
            // Ensure user data is reloaded and UI is updated accordingly
            
        }
        setSwapAvailable(false);
        setIsLoading(false);
    };
    



    

    const showAvailableTokens = () => {
      setBuyTokens(waweSwapV3BuyTokens[networkId]);
      
    };
    
 
    const exportSwaps = async () => {

      // Assuming web3 and SwapV2StorageABI are already defined
      const contractStorage = new web3.eth.Contract(WaweSwapStorageABI, waweSwapStorageV3Addresses[networkId]);
    
      let csvResult = "";
      let swapsArr = [];
    
      // Start from the head of the list
      let currentUid = await contractStorage.methods.headIndex().call();
      let lastIndex = await contractStorage.methods.lastIndex().call();
   
      while (currentUid !== lastIndex) {
        try {
          const swap = await contractStorage.methods.swaps(currentUid).call();
          console.log(currentUid + " / " + lastIndex);
          let unit = 'ether';
          if(networkId != 56){
            unit = 'mwei';
          }
          const swapAmount = parseFloat(web3.utils.fromWei(swap.amount, 'ether')).toFixed(2);
          const sellAmount = parseFloat(web3.utils.fromWei(swap.sellAmount, unit)).toFixed(2);
          const startValue = parseFloat(web3.utils.fromWei(swap.startValue, unit)).toFixed(2);
       
          swapsArr.push([swapAmount, sellAmount, startValue, swap.user, getTimeLeft(swap.createdAt)]);
          csvResult += currentUid.toString() + ";" 
          + swap.user + ";" 
          + sellAmount + ";" 
          + startValue + ";" 
          + swapAmount +  "\n";
          
          // Move to the next swap in the list
          currentUid = swap.next;
        } catch (error) {
          console.log("Iteration error or reached the end:", error);
          break; // Exit the loop in case of error (e.g., end of list)
        }
      }
    /*
      console.log("swapsArr");
      console.log(JSON.stringify(swapsArr));
    */
          // generate csv file
          console.log(JSON.stringify(swapsArr));
          const currentDateTime = getCurrentDateTimeString();
          const fileName = `swapV3_export_${currentDateTime}.csv`;
          const blob = new Blob([csvResult], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
    
    };
 

const getCurrentDateTimeString = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minute = String(now.getMinutes()).padStart(2, "0");
  const second = String(now.getSeconds()).padStart(2, "0");

  return `${day}-${month}-${year}_${hour}-${minute}-${second}`;
};


    return (
        <div className="waweSwap">
          <InfoModal link="https://official.waweswaps.com/waweswap-v3" message="Unlock new possibilities with V3 Swapping! Maximize your swapping potential with GBL liquidity, offering a unique income structure from 10% to 50% based on your purchased swapping liquidity. Regularly claim swapping income in USDT and use it to boost your V3 order, creating additional swaps without extra capital. 
Discover the power of GBL on WaweSwaps!" />
          <ToastNotification show={showToast} onClose={() => setShowToast(false)} message={toastMessage} url={toastUrl} error={toastError} />

{isLoading ? (
          <center><FontAwesomeIcon icon={faSpinnerThird} spin color="var(--maincolor)"  className='bigLoader' />
          <p className="smallTitle">
          Step <br /><b>{loadingStep} </b> of <b> {loadingNumber}</b>    <br /> {loadingText} <LoadingDots />
                            <br />
                          </p>
          </center>
        ) : (



<div className="row">


<div className="col-12 col-md-12 col-lg-12 ">

  {/*  <div className='card' >
    <div className='card-body'>
    <div className='row'>
<div className='col-6'><img src={networkIcons[networkId]} alt="" style={{ width: '15px', marginRight:"10px" }} /><label className="form-label"> {networkNames[networkId]} V3 swapping pool</label></div>
      
      <div className='col-6'><img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight:"10px" }} /><label className="form-label"> <small className='smallTitleSmall'>{swapV3Balance} <small className='smallText'> GBL</small></small></label></div>

</div>
</div>
</div>*/}
   <div className='card' >
    <div className='card-body'>
    <form className='text-center'>
          {isLoading ? (
                          <div>
                          <center>
                           
                          
                          <p>
                            <b>{loadingStep} / {loadingNumber}</b> <br /> {loadingText}
                          </p>
                          </center>
                        </div>
                          ):(<div>
            <div>
            
            
              <div className='formBox'>
              <label className="form-label">Liquidity Provision</label>
<div className='row'>
  <div className='col-6 col-md-8 col-lg-8'><input
                id="tokenAmount"
                type="text"
                className="form-control"
                placeholder="0"
                value={swapTokenAmount}
                onChange={handleBuyAmountChange}
              />
              <label className="form-label"><small>Balance: </small> {ercBalance}</label><br />
              <label className="form-label"><small>Allowance: </small> {ercAlowance}</label>
              
              </div>
  <div className='col-6 col-md-4 col-lg-4'>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect" disabled={selectDisabled} className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons[selectedLabel]} alt="" style={{ width: '20px', marginRight: '10px' }} />{selectedLabel}
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdownMenuStyle'>

        {buyTokens.map((token) => (
          <Dropdown.Item key={token.address} onClick={() => handleSelect(token)}>
            <img src={SETTINGS.tokenIcons[token.symbol]} alt="" style={{ width: '20px', marginRight: '10px' }} />{token.symbol}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
</div>

              </div>
              
              
         
            </div>
            <div className="">
              <center>
              <i className="smallIconGray">
                    <FontAwesomeIcon icon={faArrowsDownToLine}  />
                  </i>
              </center>
            </div>
            <div className="mb-3">
            
              <div className='formBox'>
<div className='row'>
<div className='col-1'><img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px' }} /></div>
      <div className='col-5'><label className="form-label">GBL liquidity: </label></div>
      <div className='col-6'><label className="form-label"> {gblBuyAmount} <small>GBL</small></label></div>

      <div className='col-1'><img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px' }} /></div>
      <div className='col-5'><label className="form-label">Swap value:  </label></div>
      <div className='col-6'><label className="form-label"> {usdtBuyValue} <small>USDT</small></label></div>

      <div className='col-1'><img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px' }} /></div>
      <div className='col-5'><label className="form-label">Swapping income: <TooltipInfoReverse /> </label></div>
      <div className='col-6'><label className="form-label"> {priceImpact} <small>%</small></label></div>

      <div className='col-1'><img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px'}} /></div>
      <div className='col-5'><label className="form-label">Target value: </label></div>
      <div className='col-6'><label className="form-label"> {usdtSellAmount} <small>USDT</small></label></div>

               
            
              

  </div>
</div>

         
              
              
         
            </div>
            <div className="d-grid gap-2">
            <small className="smallText text-left" style={{paddingBottom:"20px"}}>
            Swap V3 executions are restricted to a 30-day timeframe, ensuring responsiveness to market conditions.<br />
              By clicking the button "Swap", you agree with WaweSwaps <a href="https://docs.waweswaps.com/Terms_And_Conditions_WS.pdf" target="_blank">Terms and Conditions.</a>

                </small>
                </div>
          
            </div>)}
            <div className="d-grid gap-2">
            {swapAvailable ? ( <div>
                {isLoading ? (
                          <SpinnerBox><Spinner animation="border" variant="primary" /></SpinnerBox>
                        ) : (<button className="large-button-blue" type="button" onClick={handleSwap}>Swap</button>)}
                        </div>):(
                <div>
                <button className="large-button-blue-disabled" type="button" >Swap</button>
                <center><div className="error-text error-text" >{errorText}</div></center>
                </div>
            )}
            </div>
            <div className="d-grid gap-2">
            <small className="smallText text-left" style={{paddingTop:"20px"}}>
              Swapping income is distributed once a sufficient amount of total BNB GAS has accumulated to execute the GBL distribution function inside the V3 smart contract.
                </small>
</div>
          </form>
    </div>
   </div>

</div>
<div className="col-12 col-md-12 col-lg-12">
<br />
   <div className="row">


<div className="col-3 col-lg-4"><small className='smallTitle'>Income:</small></div>
<div className="col-6 col-lg-4"><img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '20px', marginRight: '10px', marginTop:"-3px" }} /> {unclaimedRewards} <small>USDT</small></div>
<div className="col-3 col-lg-4"><button type="button" className="mediumButton" style={{marginTop:"0px"}}  onClick={handleShowCollectModal}>Claim</button></div>
</div>

<Accordion defaultActiveKey="0">

{userSwaps.map((swap, index) => (
  <Card key={index}>
      <Card.Header>
  <div className="row ">
  <div className="col-5">
    <small className='smallTitleSmall' style={{ lineHeight: '30px', marginTop: "5px" }}> <img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px', marginRight: '10px', marginTop:"-3px" }} /> {swap.soldAmount} <small className='smallText'> USDT</small></small>
    </div>

  <div className="col-1">

   
    </div>
    <div className="col-5">
    <small className='smallTitleSmall' style={{ lineHeight: '30px', marginTop: "5px" }}> <img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px', marginRight: '10px', marginTop:"-3px" }} />{swap.maxSellAmount} <small className='smallText'> USDT</small> </small>
    </div>
  <div className="col-1"> <ContextAwareToggle eventKey={String(index)}></ContextAwareToggle></div>
    
    <div className="col-12">
<ProgressBar now={swap.soldAmount} min={0} max={swap.maxSellAmount} striped variant="info" animated className='swapProgress' ></ProgressBar>

</div>
</div>
  </Card.Header>

  <Accordion.Collapse eventKey={String(index)}>
    <Card.Body>

    <div className="row">
                  
               
                    <div className="col-12 col-lg-6">
                    <label className="form-label">Swap value </label><br />
               <small> <img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.startValue} USDT</small>
               <br />
                    <br />
                    </div>
                    <div className="col-12 col-lg-6">
                    <label className="form-label">Outstanding </label><br />
               <small> <img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.sellAmount} USDT</small>
               <br />
                    <br />
                    </div>
                    <div className="col-12 col-lg-6">
                    <label className="form-label">Swapping </label><br />
               <small> <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.amount} GBL</small>
                    <br />
                    <br />
                    </div>
                    <div className="col-12 col-lg-4">
                    <label className="form-label">Earned VGBL  </label><br />
               <small> <img src={SETTINGS.tokenIcons["VGBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.amount} VGBL</small>
               <br />
               <br />
                    </div>
                
              
                   
                    
                    
                  </div>

    </Card.Body>
  </Accordion.Collapse>
</Card>
))}

      
    
    </Accordion>

</div>
</div>




        )}

    
        
           
        <div className="row">

       
     

            
       


            <div className="col-12 col-lg-6 text-center">
            <Modal show={showCollectModal} onHide={handleCloseCollectModal}>
                    <Modal.Header closeButton>
                      <Modal.Title><center>Claim Income</center></Modal.Title>
                      
                    </Modal.Header>
                    <Modal.Body>
            <div className="card ">
                  <div className="card-body text-center">
                   
                    <strong>Available USDT Income:</strong>
           
                <input
                    type="text"
                    value={unclaimedRewards}
                    disabled
                />
                    <strong>Amount to Claim:</strong>
           
                <input
                    type="text"
                    value={unclaimedRewardsNet}
                    disabled
                />
                    <strong>Claim Income Processing Fee:</strong>
           
                <input
                    type="text"
                    value={unclaimedRewardsFee}
                    disabled
                />
                
                <br />

                <br />
                {unclaimedRewards > 0 ? (<>
                {isLoading ? (
                          <SpinnerBox><Spinner animation="border" variant="primary" /></SpinnerBox>
                        ) : (<button className="large-button-blue" type="button" onClick={collectIncome}>Claim</button>)}
                        </> ) : (null)}
                        
           
            </div>
            </div>
            <br />
            <small className='smallText'>
                  <b> Swapping Income Claiming Fee Requests:</b>
<ul >
<li>Up to 99 USDT: 2%</li>
<li>From 100 to 999 USDT: 1%</li>
<li>1000 USDT onward: 0.5%</li>
</ul>
                  </small>
            </Modal.Body>
                    <Modal.Footer>
                    
                    </Modal.Footer>
                  </Modal>

            </div>

       

  


        
</div>

       
        </div>
    );
}

export default WaweSwap;
