import { createContext, useContext, useState, useEffect } from "react";
import Web3 from "web3";
import SETTINGS from "./settings";

const Web3Context = createContext();

export const useWeb3 = () => useContext(Web3Context);

export const Web3Provider = ({ children }) => {
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [networkName, setNetworkName] = useState("");
  const [networkSymbol, setNetworkSymbol] = useState("");
  const [networkId, setNetworkId] = useState(56);


  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        const networkId = await web3.eth.net.getId();
        
        // Network IDs: Ethereum Mainnet (1), BSC Mainnet (56), Polygon Mainnet (137)
        switch(networkId) {
          case 1: // Ethereum Mainnet
            console.log("Already connected to the Ethereum network");
            break;
          case 56: // BSC Mainnet
            console.log("Already connected to the Binance Smart Chain network");
            break;
          case 137: // Polygon Mainnet
            console.log("Already connected to the Polygon network");
            break;
          case 10: // optimism Mainnet
            console.log("Already connected to the Optimism network");
            break;
          case 42161: // arbitrum Mainnet
            console.log("Already connected to the Arbitrum network");
            break;
          default:
            // If not connected to any of the specified networks, switch to BSC
            await changeToBinanceNetwork();
        }
  
        const requestedAccounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        
        // Assuming setWeb3, setAccounts, setSelectedAccount, and setNetworkName are functions defined elsewhere to update your app's state
        setWeb3(web3);
        setAccounts(requestedAccounts);
        //setSelectedAccount("0x06891951D013F17C365B401fEE22b897f114BFd7");
        setSelectedAccount(requestedAccounts[0]);
  
        // Update networkId and networkName after any potential network switch
        const updatedNetworkId = await web3.eth.net.getId();
        const networkName = getNetworkName(updatedNetworkId); // Ensure you have a getNetworkName function that maps network IDs to their names
        const networkSymbol = getNetworkSymbol(updatedNetworkId); // Ensure you have a getNetworkName function that maps network IDs to their names
        setNetworkId(updatedNetworkId);
        setNetworkName(networkName);
        setNetworkSymbol(networkSymbol);
        
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
      }
    } else {
      console.error("MetaMask not detected. Please install the MetaMask extension.");
    }
  };
  

  const changeNetwork = async (id) => {
      if(id == 1) await changeToEthereumNetwork();
      if(id == 56) await changeToBinanceNetwork();
      if(id == 137) await changeToPolygonNetwork();
      if(id == 10) await changeToOptimismNetwork();
      if(id == 42161) await changeToArbitrumNetwork();

  }

  

  const changeToEthereumNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }], // Ethereum Mainnet ID
        });
      } catch (switchError) {
        console.error("Failed to switch to Ethereum Mainnet", switchError);
      }
    } else {
      console.error("Cannot setup Ethereum Mainnet on this browser");
    }
  };

  const changeToPolygonNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x89' }], // Polygon Mainnet ID
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x89',
                  chainName: 'Polygon Network',
                  nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18
                  },
                  rpcUrls: ['https://polygon-pokt.nodies.app'],
                  blockExplorerUrls: ['https://polygonscan.com/'],
                },
              ],
            });
          } catch (addError) {
            console.error("Failed to add Polygon Network to MetaMask");
          }
        } else {
          console.error("Failed to switch to Polygon Network");
        }
      }
    } else {
      console.error("Cannot setup Polygon Network on this browser");
    }
  };
  

  const changeToBinanceNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet ID
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x38',
                  chainName: 'Binance Smart Chain',
                  nativeCurrency: {
                    name: 'BNB',
                    symbol: 'bnb',
                    decimals: 18
                  },
                  rpcUrls: ['https://bsc-dataseed.binance.org/'],
                  blockExplorerUrls: ['https://bscscan.com/'],
                },
              ],
            });
          } catch (addError) {
            console.error("Failed to add Binance Smart Chain to MetaMask");
          }
        } else {
          console.error("Failed to switch to Binance Smart Chain");
        }
      }
    } else {
      console.error("Cannot setup Binance Smart Chain on this browser");
    }
  };


  const changeToOptimismNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0xa' }], // Optimism Mainnet ID
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0xa',
                  chainName: 'Optimism',
                  nativeCurrency: {
                    name: 'Ether',
                    symbol: 'ETH',
                    decimals: 18
                  },
                  rpcUrls: ['https://mainnet.optimism.io'],
                  blockExplorerUrls: ['https://optimistic.etherscan.io'],
                },
              ],
            });
          } catch (addError) {
            console.error("Failed to add Optimism to MetaMask");
          }
        } else {
          console.error("Failed to switch to Optimism");
        }
      }
    } else {
      console.error("Cannot setup Optimism on this browser");
    }
  };

  
  const changeToArbitrumNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0xa4b1' }], // Arbitrum One Mainnet ID
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0xa4b1',
                  chainName: 'Arbitrum One',
                  nativeCurrency: {
                    name: 'Ether',
                    symbol: 'ETH',
                    decimals: 18
                  },
                  rpcUrls: ['https://arb1.arbitrum.io/rpc'],
                  blockExplorerUrls: ['https://arbiscan.io'],
                },
              ],
            });
          } catch (addError) {
            console.error("Failed to add Arbitrum One to MetaMask");
          }
        } else {
          console.error("Failed to switch to Arbitrum One");
        }
      }
    } else {
      console.error("Cannot setup Arbitrum One on this browser");
    }
  };
  


  const getNetworkSymbol = (networkId) => {
    switch (networkId) {
      case 1:
        return "ETH";
      case 137:
        return "MATIC";
      case 56:
        return "BNB";
      case 10:
        return "ETH";
      case 42161:
        return "ETH";
      default:
        return "Unknown";
    }
  };

  const getNetworkName = (networkId) => {
    return networkNames[networkId];
  };

  const networkList = [
    {"id": 1, "name": "Ethereum"},
    {"id": 10, "name": "Optimism"},
    {"id": 137, "name": "Polygon"},
    {"id": 56, "name": "BNB Smart Chain"},
    {"id": 42161, "name": "Arbitrum"},
  ];
  const networkIcons = {
    1:"https://docs.waweswaps.com/images/chains/ethereum.svg",
    10:"https://docs.waweswaps.com/images/chains/optimism.svg",
    137:"https://docs.waweswaps.com/images/chains/polygon.svg",
    56:"https://docs.waweswaps.com/images/chains/smartchain.svg",
    42161:"https://docs.waweswaps.com/images/chains/arbitrum.svg",
  };
  const networkNames = {
    1:"Ethereum",
    10:"Optimism",
    137:"Polygon",
    56:"BNB Smart Chain",
    42161:"Arbitrum",
  };
  const waweSwapV3Addresses = {
    1:"",
    10:SETTINGS.waweSwapAddressOptimism,
    137:SETTINGS.waweSwapAddressPolygon,
    56:SETTINGS.waweSwapAddressBsc,
    42161:SETTINGS.waweSwapAddressArbitrum,
  };
  const waweSwapStorageV3Addresses = {
    1:"",
    10:SETTINGS.waweSwapStorageAddressOptimism,
    137:SETTINGS.waweSwapStorageAddressPolygon,
    56:SETTINGS.waweSwapStorageAddressBsc,
    42161:SETTINGS.waweSwapStorageAddressArbitrum,
  };
  const waweSwapV3BuyTokens = {
    1:SETTINGS.buyTokensDataEthereum,
    10:SETTINGS.buyTokensDataOptimism,
    137:SETTINGS.buyTokensDataPolygon,
    56:SETTINGS.buyTokensData,
    42161:SETTINGS.buyTokensDataArbitrum,
  };

  const disconnect = () => {
    setSelectedAccount(null);
    setAccounts(null);
    setNetworkName("");
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (newAccounts) => {
        disconnect();
      });
    }
  }, [networkName]);

  return (
    <Web3Context.Provider
      value={{
        web3,
        accounts,
        selectedAccount,
        connectMetaMask,
        disconnect,
        networkName,
        networkId,
        changeToEthereumNetwork,
        changeToBinanceNetwork,
        changeToPolygonNetwork,
        networkSymbol,
        changeNetwork,
        networkList,
        networkIcons,
        networkNames,
        waweSwapV3Addresses,
        waweSwapV3BuyTokens,
        waweSwapStorageV3Addresses,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

