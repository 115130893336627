import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { useWeb3 } from "../Web3Context"; // Make sure you have this context setup
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import WaweSwapsABI from "../abis/WaweSwapsV3ABI";
import WaweSwapStorageABI from "../abis/WaweSwapStorageV3ABI";
import SETTINGS from "../settings";
import styled from "@emotion/styled";
import BigNumber from "bignumber.js";

const ClaimButton = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: #999999;
  font-weight: bold;
  font-size: 10px;
  border: 1px solid #999999;
  padding: 5px 20px;
  border-radius:100px;
  cursor: pointer;
  margin-left:0px;
 margin-top:12px;
  transition: all 0.2s;
  text-align:center;
  &:hover {
    
    border: 1px solid var(--maincolor);
    color: var(--maincolor);
    transition: all 0.6s;
  }
`;

const AdminPanelV3 = () => {
  const { web3, selectedAccount, networkId, networkSymbol, networkNames, waweSwapV3Addresses, waweSwapStorageV3Addresses } = useWeb3(); // Assuming you have web3 context
  const [contract, setContract] = useState(null);
  const [fee, setFee] = useState(0);
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [autoDistribute, setAutoDistribute] = useState(false);
  const [liquidityPercent, setLiquidityPercent] = useState(0);
  const [gblBuyPercent, setGblBuyPercent] = useState(0);
  const [timestampPercent, setTimestampPercent] = useState(0);
  const [distributePercent, setDistributePercent] = useState(0);

  useEffect(() => {
    if (web3 && selectedAccount) {
      const contractInstance = new web3.eth.Contract(WaweSwapsABI, waweSwapV3Addresses[networkId]);
      setContract(contractInstance);
      loadContractSettings(contractInstance);
    }
  }, [web3, selectedAccount]);

  const loadContractSettings = async (contractInstance) => {
    const fee = await contractInstance.methods.FEE().call();
    const autoDistribute = await contractInstance.methods.AUTO_DISTRIBUTE().call();
    const liquidityPercent = await contractInstance.methods.LIQUIDITY_PERCENT().call();
    // Load other settings similarly
    setFee(fee);
    setAutoDistribute(autoDistribute);
    setLiquidityPercent(liquidityPercent);
    // Set other states similarly
  };

  const updateFee = async () => {
    if (contract) {
      await contract.methods.changeFEE(fee).send({
        from: selectedAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    }
  };

  const updateAutoDistribute = async () => {
    if (contract) {
      await contract.methods.updateAutoDistribute(autoDistribute).send({
        from: selectedAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    }
  };

  const updateLiquidityPercent = async () => {
    if (contract) {
      await contract.methods.updateDistributePercent(liquidityPercent, gblBuyPercent, timestampPercent, distributePercent).send({
        from: selectedAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    }
  };
  const updateGblBuyPercent = async () => {
    if (contract) {
      await contract.methods.updateDistributePercent(liquidityPercent, gblBuyPercent, timestampPercent, distributePercent).send({
        from: selectedAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    }
  };

  const updateTimestampPercent = async () => {
    if (contract) {
      await contract.methods.updateDistributePercent(liquidityPercent, gblBuyPercent, timestampPercent, distributePercent).send({
        from: selectedAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    }
  };

  const updateDistributePercent = async () => {
    if (contract) {
      await contract.methods.updateDistributePercent(liquidityPercent, gblBuyPercent, timestampPercent, distributePercent).send({
        from: selectedAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
    }
  };
  
  
  
  

  // Add similar functions for updating other contract settings

  return (
    <div>
      <ClaimButton onClick={(e) => setShowAdminPanel(!showAdminPanel)}>Show admin panel</ClaimButton>
      {showAdminPanel ?(
      <Form>
  {/* Existing Fee Update Form Group */}
  <div className='formAdminBlock'>
    <label>Fee</label>
    <input
      type="number"
      className="form-control-small"
      placeholder="Enter new fee"
      value={fee}
      onChange={(e) => setFee(e.target.value)}
    />
    <ClaimButton onClick={updateFee}>Update Fee</ClaimButton>
  </div>

  {/* Auto Distribute Toggle */}
  <div className='formAdminBlock'>
    <label>Auto Distribute</label>
    <select className="form-control-small" value={autoDistribute} onChange={(e) => setAutoDistribute(e.target.value === 'true')}>
      <option value="true">Enabled</option>
      <option value="false">Disabled</option>
    </select>
    <ClaimButton onClick={updateAutoDistribute}>Update Auto Distribute</ClaimButton>
  </div>

  {/* Liquidity Percent Update */}
  <div className='formAdminBlock'>
    <label>Liquidity Percent</label>
    <input
      type="number"
      className="form-control-small"
      placeholder="Enter new liquidity percent"
      value={liquidityPercent}
      onChange={(e) => setLiquidityPercent(e.target.value)}
    />
    <ClaimButton onClick={updateLiquidityPercent}>Update Liquidity Percent</ClaimButton>
  </div>

  {/* GBL Buy Percent Update */}
  <div className='formAdminBlock'>
    <label>GBL Buy Percent</label>
    <input
      type="number"
      className="form-control-small"
      placeholder="Enter new GBL buy percent"
      value={gblBuyPercent}
      onChange={(e) => setGblBuyPercent(e.target.value)}
    />
    <ClaimButton onClick={updateGblBuyPercent}>Update GBL Buy Percent</ClaimButton>
  </div>

  {/* Timestamp Percent Update */}
  <div className='formAdminBlock'>
    <label>Timestamp Percent</label>
    <input
      type="number"
      className="form-control-small"
      placeholder="Enter new timestamp percent"
      value={timestampPercent}
      onChange={(e) => setTimestampPercent(e.target.value)}
    />
    <ClaimButton onClick={updateTimestampPercent}>Update Timestamp Percent</ClaimButton>
  </div>

  {/* Distribute Percent Update */}
  <div className='formAdminBlock'>
    <label>Distribute Percent</label>
    <input
      type="number"
      className="form-control-small"
      placeholder="Enter new distribute percent"
      value={distributePercent}
      onChange={(e) => setDistributePercent(e.target.value)}
    />
    <ClaimButton onClick={updateDistributePercent}>Update Distribute Percent</ClaimButton>
  </div>
</Form>
):(<></>)}
    </div>
  );
};

export default AdminPanelV3;
