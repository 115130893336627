import React, { useState, useEffect } from "react";
import { useWeb3 } from "./Web3Context";
import Web3 from "web3";
import tokenSwapABI from "./abis/TokenSwapABI";
import UserStakes from "./components/UserStakes";
import TruncatedText from "./components/TruncatedText";
import Admin from "./components/Admin";
import Bridge from "./components/Bridge";
import HalvingBalances from "./components/NetworkPrices";
import Wallet from "./components/Wallet";
import WaweSwap from "./components/WaweSwap";
import WaweSwapRecycle from "./components/WaweSwapRecycle";
import FloatingMenu from "./components/FloatingMenu";
import styled from "@emotion/styled";
import logo from "./logo.png";
import ws from "./ws.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { detect } from "detect-browser";


import { faYoutube, faTelegram, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faWallet, faSwap, faBolt, faLandmark, faChartPie,faRotate,
  faArrowRightArrowLeft,
  faSpinner,
  faArrowsRotate,
  faLinkSlash,
  faRecycle} from '@fortawesome/pro-light-svg-icons'

import SETTINGS from "./settings";
import { ERC20_ABI } from "./abis/erc20";



const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.3));
  
  border-radius: 5px 5px 5px 5px;
  width: 100%;
  margin: 0 auto;
  box-shadow:0 4px 24px 0 rgba(0,0,0,.08);
  position:relative;
  z-index:2;
  margin-top:-15px;
`;
const ContainerSwap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background: transparent;
  
  border-radius: 30px;
  width: 100%;
  margin: 0 auto;
  position:relative;
  z-index:2;
  margin-top:-15px;
`;
const MenuContainer = styled.div`
  display: block;
  padding: 0px;
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;
`;

const ConnectButton = styled.button`
  background-color: var(--maincolor);
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 50px;
  border: none;
  padding: 5px 30px;
  float:right;
  margin-top:8px;
  cursor: pointer;
  transition:all 0.3s;
  &:hover {
    color: #ffffff;
    transition:all 0.3s;
  }
`;

const ToggleButton = styled.div`
  display:inline-block !important;
  float:left;
  position:relative;
  background-color: transparent;
  color: #555555;
  font-weight: 400;
  font-size: 16px;
  border: none;
  padding: 0px 5px;
  border-radius:100px;
  cursor: pointer;
  
  transition: all 0.1s;
  padding-top:10px;
  margin-right:10px;
  margin-left:10px;
  line-height:35px;
  &:hover {
    color: var(--maincolor);
    
    transition: all 0.3s;
  }
  & i {
    font-size:16px;
    margin-right:10px;
    display:inline-block;

  }
`;

const PasswordInput = styled.input`
  border: 1px solid #ced4da;
  border-radius: 30px;
  padding: 0.5rem;
  width: 100%;
`;

const minABI = [
  // name
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [{ name: "", type: "string" }],
    type: "function",
  },
  // symbol
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
    type: "function",
  },
];

const MetaMaskLogin = () => {
  const { web3, selectedAccount, connectMetaMask, disconnect, networkName, networkId } =
    useWeb3();

  const [isPassword, setIsPassword] = useState(!SETTINGS.maintenanceMode);
  const [activeContainer, setActiveContainer] = useState("swap");
  const [activeSwapContainer, setActiveSwapContainer] = useState("waweSwap");
  const [tokens, setTokens] = useState([]);
  const [passwordState, setPasswordState] = useState("");
  const [gblBalance, setGblBalance] = useState(0);
  const [vgblBalance, setVgblBalance] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [bridgeFromNetworkId, setBridgeFromNetworkId] = useState(0);

  const getBalanceGbl = async () => {
    if (!Web3.utils.isAddress(SETTINGS.globalTokenAddress)) {
      ////alert("Please enter a valid contract address.");
      return;
    }

    const tokenContractGbl = new web3.eth.Contract(
      ERC20_ABI,
      SETTINGS.globalTokenAddress
    );

    try {
      const balance = await tokenContractGbl.methods
        .balanceOf(selectedAccount)
        .call();
      const decimals = await tokenContractGbl.methods.decimals().call();
      const formattedBalance = balance / 10 ** decimals;
      setGblBalance(formattedBalance.toFixed(2));
    } catch (error) {
      console.error("Error fetching token balance:", error);
    }
  };

  const getBalanceVgbl = async () => {
    if (!Web3.utils.isAddress(SETTINGS.voteGlobalTokenAddress)) {
      ////alert("Please enter a valid contract address.");
      return;
    }

    const tokenContract = new web3.eth.Contract(
      ERC20_ABI,
      SETTINGS.voteGlobalTokenAddress
    );

    try {
      const balance = await tokenContract.methods
        .balanceOf(selectedAccount)
        .call();
      const decimals = await tokenContract.methods.decimals().call();
      const formattedBalance = balance / 10 ** decimals;
      setVgblBalance(formattedBalance.toFixed(2));
    } catch (error) {
      console.error("Error fetching token balance:", error);
    }
  };

  const getEthBalance = async () => {
    if (!Web3.utils.isAddress(selectedAccount)) {
      alert("Please enter a valid Ethereum address.");
      return;
    }

    try {
      const balanceWei = await web3.eth.getBalance(selectedAccount);
      const balanceEth = web3.utils.fromWei(balanceWei, "ether");
      setEthBalance(parseFloat(balanceEth).toFixed(4));
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  const getBalances = () => {
    getBalanceGbl();
    getEthBalance();
    getBalanceVgbl();
  };

  const handlePasswordChange = (event) => {
    setPasswordState(event.target.value);
  };



  const handleDisconnect = () => {
    disconnect();
  };

  const showBridge = (_fromNetworkId) => {
    setBridgeFromNetworkId(_fromNetworkId);
    setActiveContainer("bridge");
  
  };

  

const isMaintenance = () => {
    
  // Construct the URL with the address and amount parameters
  const url = `https://waweswaps.global/maintenance/isMaintenance.php`;

  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.text();
    })
    .then(data => {
      if(data!="5"){
        setIsPassword(false);
        handleDisconnect();
      }
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });

    setTimeout(function () {
      isMaintenance();
    }, 30000);


};


  const showWallet = () => {
   
    setActiveContainer("wallet");
  
  };
  const showSwaps = () => {
    setActiveContainer("swap");
    if(networkId == 56){
      setActiveSwapContainer("waweSwap");
    }else{
      setActiveSwapContainer("waweSwap");
      
    }
    
  };
  const showWaweSwap = () => {
    setActiveContainer("swap");
    setActiveSwapContainer("waweSwap");

  };
  const showWaweSwapRecycle = () => {
    setActiveContainer("swap");
    setActiveSwapContainer("waweSwapRecycle");
  };

  
  const showAdmin = () => {
    setActiveContainer("admin");

   
  };

  const showStakes = () => {

    setTokens(SETTINGS.tokensData);
    let timeout = 500;
    if (tokens.length > 0) timeout = 0;
    setTimeout(function () {
      setActiveSwapContainer("stakes");


    }, timeout);
  };
  const setPassword = () => {
    if (passwordState === SETTINGS.maintenancePassword) {
      setIsPassword(true);
    } else {
      alert("Wrong password");
    }
  };


  const openDao = () => {
    window.open("https://snapshot.org/#/waweswaps.eth", "_blank").focus();
  };

  const openRevoke = () => {
    window.open(SETTINGS.revokeLinks[networkId], "_blank").focus();
  };


  useEffect(() => {
    if (selectedAccount) {

      if(activeContainer == ""){
       
        showWaweSwap();
       
        
        isMaintenance();
      }

        showWaweSwap();
      
    }
  }, [selectedAccount, ethBalance, gblBalance, vgblBalance, networkId]);

  return (
    <>
   
  <Container className="white-container-top">
 
  
   
      <iframe src="https://news.waweswaps.com/" scrolling="no" className='iframe'></iframe>
        </Container>
      {true ? (
        <>
        
        <HalvingBalances showBridgeFunction={showBridge} />
          <Container className="white-container container-fix" style={{ zIndex: "3" }}>
            
            <div className="row">
            <div className="col-12 col-lg-9">

            <MenuContainer className="hideOnMobile desktopMenu">
         
            <ToggleButton>
                  <img src={ws} width={140} style={{marginTop:"-5px"}}/>
          
                </ToggleButton>
       
            <ToggleButton onClick={showSwaps} className={`${activeContainer === 'swap' ? 'activeBtn' : ''}`}>
                  <i>
                    <FontAwesomeIcon icon={faSwap}  />
                  </i>
                  Swaps
                </ToggleButton>
            

 
 <ToggleButton onClick={openDao}>
                  <i>
                    <FontAwesomeIcon icon={faBolt}  />
                  </i>
                  DAO
                </ToggleButton>
 
 <ToggleButton onClick={openRevoke}>
                  <i>
                    <FontAwesomeIcon icon={faLinkSlash}  />
                  </i>
                  Revoke
                </ToggleButton>
              
         
             
            </MenuContainer>
            

            </div>
              <div className="col-12 col-lg-3"
                style={{ textAlign: "left", position:"relative" }}
              >
                
                  {networkName && (
                  <span className="small-text">
                     <FloatingMenu showWalletFunction={showWallet} />
                  <TruncatedText
                    text={selectedAccount}
                    networkName=""
                    disconectFunction={handleDisconnect}
                    maxLength={8}
                    title=""
                    colored={true}
                  />
                </span>
                )}
                  {!selectedAccount && (
                  <ConnectButton onClick={connectMetaMask}>
                        Connect
                      </ConnectButton>)}
                
              </div>
              
            </div>
            
            <MenuContainer className="showOnMobile" style={{margin:"0 auto", display:"flex !important"}}>
      
            <ToggleButton onClick={showSwaps} className={`${activeContainer === 'swap' ? 'activeBtn' : ''}`}>
                  <i>
                    <FontAwesomeIcon icon={faSwap}  />
                  </i>
                  Swaps
                </ToggleButton>
            

        
 
 <ToggleButton onClick={openDao}>
                  <i>
                    <FontAwesomeIcon icon={faBolt}  />
                  </i>
                  DAO
                </ToggleButton>
                <ToggleButton onClick={openRevoke}>
                  <i>
                    <FontAwesomeIcon icon={faLinkSlash}  />
                  </i>
                  Revoke
                </ToggleButton>
              
              
            </MenuContainer>
            
            
            
          </Container>
         
          
          
            
          <div className="row">
          <div className="col-12 col-lg-3"></div>
          <div className="col-12 col-lg-6" style={{marginLeft:20}}>

       
             
         {activeContainer === "swap" && (<>
         <div className="containerMenu">
       <button className={`containerMenuBtn ${activeSwapContainer === 'waweSwap' ? 'active' : ''}`} onClick={showWaweSwap}>
      
    <i><FontAwesomeIcon icon={faArrowRightArrowLeft} /></i>Swap V3</button>
    {networkId === 56 && (<button className={`containerMenuBtn ${activeSwapContainer === 'waweSwapRecycle' ? 'active' : ''}`} onClick={showWaweSwapRecycle}>
                  <i><FontAwesomeIcon icon={faRecycle} /></i>Swap V2</button>)}
                  {networkId === 56 && (<button className={`containerMenuBtn ${activeSwapContainer === 'stakes' ? 'active' : ''}`} onClick={showStakes}>
                  <i><FontAwesomeIcon icon={faArrowsRotate} /></i>Swap V1</button>)}
  </div>
              {activeSwapContainer === "waweSwapRecycle" && (
            <ContainerSwap id="waweSwapRecycle">
              <WaweSwapRecycle id="waweSwap" ></WaweSwapRecycle>
            </ContainerSwap>
          )}
          {activeSwapContainer === "waweSwap" && (
            <ContainerSwap id="waweSwap">
              <WaweSwap id="waweSwap" ></WaweSwap>
            </ContainerSwap>
          )}
          {activeSwapContainer === "stakes" && (
           <ContainerSwap id="waweSwap">
              <UserStakes balanceFunction={getBalances} />
              </ContainerSwap>
          )}

         </>)}
         
  
  
          {activeContainer === "wallet" && (
    
              <Wallet id="wallet" showBridgeFunction={showBridge}></Wallet>
           
          )}
 
      
          {activeContainer === "bridge" && (
       
              <Bridge
                id="bridge"
                fromNetworkId={bridgeFromNetworkId}
                backFunction={showWallet}
              ></Bridge>
          )}
          </div>
          </div>
          {activeContainer === "admin" && (
            <Container id="admin">
              <Admin
                id="admin"
                balanceFunction={getBalances}
              ></Admin>
            </Container>
          )}
        </>
      ) : (
        <div>
          {isPassword === true && (
            <>
            <br />
            <Container className="container-fix">
            <br />
            <br />
            <br />
              <img src={logo} alt="Logo" width="100px" />
             
              <div className="boxTitle " style={{ textAlign: "center", fontSize: "18pt" }}>
                <span style={{ fontSize: "40pt", textTransform: "none" }}>WaweSwaps</span><br />
                <small style={{ fontSize: "13pt" }}>Unlock the liquidity of your assets efficiently!</small></div>
              <br />
              {(() => {
                const browser = detect();
                if (
                  browser &&
                  (browser.os === "iOS" || browser.os === "Android OS")
                ) {
                  // Show button to download MetaMask app
                  if (window.ethereum) {
                    return (
                      <ConnectButton onClick={connectMetaMask}>
                        Connect with MetaMask
                      </ConnectButton>
                    );
                  } else {
                    return (
                      <ConnectButton
                        onClick={() => {
                          if (browser.os === "iOS") {
                            window.location.href =
                              "https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202";
                          } else if (browser.os === "Android OS") {
                            window.location.href =
                              "https://play.google.com/store/apps/details?id=io.metamask&hl=en&gl=US";
                          }
                        }}
                      >
                        Download MetaMask App
                      </ConnectButton>
                    );
                  }
                } else if (window.ethereum) {
                  // Connect with MetaMask if it is installed
                  return (
                    <ConnectButton onClick={connectMetaMask}>
                      Connect with MetaMask
                    </ConnectButton>
                  );
                } else {
                  // Notify the user to install MetaMask
                  return (
                    <p>
                      Please install MetaMask. You can download it{" "}
                      <a href="https://metamask.io/download/">here!</a>
                    </p>
                  );
                }
              })()}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Container>
            </>
          )}
          {isPassword === false && (
            <>
            <br />
            <Container className="container-fix">
             
            <br />
              <br />
              <br />
              <br />
              <br />
              <img src={logo} alt="Logo" width="80px" />
              <br />
              <h3>Maintenance mode</h3>
              <div className="row">
        <div className="col-12 col-md-4"></div>
        <div className="col-12 col-md-4">
        <br />
              <PasswordInput
                id="password"
                type="password"
                onChange={handlePasswordChange}
              />
              </div>
              </div>
              <br />
              <ConnectButton onClick={setPassword}>Login</ConnectButton>
              <br />
              <br />
              <br />
            </Container>
            </>
          )}
        </div>
      )}
      <br />

      <div className="row" style={{ zIndex: 2 }}>
     
        
        
      <br />
        <br />
        <div className="col-12">
          
          <a
            className="termsLinkIcon"
            target="_blank"
            href="https://twitter.com/waweswaps"
          >
            <FontAwesomeIcon icon={faSquareXTwitter}  />
          </a>
          <a
            className="termsLinkIcon"
            target="_blank"
            href="https://t.me/waweswaps"
          >
            <FontAwesomeIcon icon={faTelegram}  />
          </a>
          <a
            className="termsLinkIcon"
            target="_blank"
            href="https://www.youtube.com/@WaweSwaps"
          >
            <FontAwesomeIcon icon={faYoutube}  />
          </a>
        </div>
        <br />
        <br />
        <div className="col-12">
          <a
            className="termsLink"
            target="_blank"
            href="https://docs.waweswaps.com/Whitepaper_WS.pdf"
          >
            Whitepaper
          </a>
          <a
            className="termsLink"
            target="_blank"
            href="https://official.waweswaps.com/"
          >
            Documentation
          </a>
          <a
            className="termsLink"
            target="_blank"
            href="https://docs.waweswaps.com/Terms_And_Conditions_WS.pdf"
          >
            Terms
          </a>
          <a
            className="termsLink"
            target="_blank"
            href="https://snapshot.org/#/waweswaps.eth"
          >
            DAO
          </a>
          <a
            className="termsLink"
            target="_blank"
            href="https://coinmarketcap.com/currencies/global-token/"
          >
            CoinMarketCap
          </a>
          <a
            className="termsLink"
            target="_blank"
            href="https://www.coingecko.com/en/coins/waweswaps-global-token            "
          >
            CoinGecko
          </a>
     
      
      
        
      
        <a
            className="termsLink"
            target="_blank"
            href="https://official.waweswaps.com/contact/customer-support"
          >
            Contact
          </a>
      
       
          {selectedAccount &&(<>
          {selectedAccount.toLowerCase() ===
              SETTINGS.ownerAddress.toLowerCase() && (
                <a
                className="termsLink"
                onClick={showAdmin}
              >
                Admin
              </a>
                
              )}</>
              )}
        </div>
       
      </div>
    </>
  );
};

export default MetaMaskLogin;
