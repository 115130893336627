import React, { useState, useEffect, useRef } from "react";
import { useWeb3 } from "../Web3Context";
import Web3 from 'web3';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faRightLong, faSpinnerThird, faArrowRightArrowLeft, faPlus } from '@fortawesome/pro-thin-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import styled from "@emotion/styled";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import SETTINGS from "../settings";
import { ERC20_ABI } from "../abis/erc20";
import Modal from "react-bootstrap/Modal";
import Dropdown from 'react-bootstrap/Dropdown';
import ToastNotification from './ToastNotification';
import SortingTable from './SortingTable';
import InfoModal from "./InfoModal";
const API_BASE_URL = SETTINGS.backendUrl;

const TokenSelect = styled.select`
  padding: 10px; // Increase padding for a larger appearance
  border:0px;
  border-radius: 100px;
  margin:0 auto;
  appearance: none;
  color: var(--maincolor);
  background-color:#FFFFFF;
  width: 100%;
  font-weight:600;
  font-size:15px;
  margin-top: 0.25rem;
  cursor:pointer;
  box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  text-align-last: center; // Center align the text
  text-align: center; // Center align the text in some browsers
  &:disabled {
    color: var(--maincolor);
  }
  & option {
    font-size:15px;
  }
`;
const SpinnerBox = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: var(--maincolor);
  font-weight: bold;
  font-size: 15px;
  border: 1px solid var(--maincolor);
  padding: 10px;
  border-radius:100px;
  cursor: pointer;
  width:100%;
  transition: all 0.2s;
  text-align:center;
`;

const Bridge = ({fromNetworkId, backFunction}) => {
  const { web3, selectedAccount, connectMetaMask, changeNetwork, networkId, networkList, networkIcons, networkNames, networkSymbol } = useWeb3();
  const [bridges, setBridges] = useState([]);
  const [bridgesTable, setBridgesTable] = useState([]);
  const [claimedBridges, setClaimedBridges] = useState([]);
  const [finishedBridges, setFinishedBridges] = useState([]);
  const [gblAddress, setGblAddress] = useState(SETTINGS.globalTokenAddress);
  const [selectedFromNetwork, setSelectedFromNetwork] = useState(fromNetworkId);
  const [selectedToNetwork, setSelectedToNetwork] = useState(networkId);
  const [claimBridgeId, setClaimBridgeId] = useState('');
  const [closeBridgeId, setCloseBridgeId] = useState('');
  const [claimBridgeAmountOut, setClaimBridgeAmountOut] = useState('');
  const [claimBridgeAmountIn, setClaimBridgeAmountIn] = useState('');
  const [bridgeAmount, setBridgeAmount] = useState('');
  const [receiveAmount, setReceiveAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showBridgeModal, setShowBridgeModal] = useState(false);
  const [showClaimBridgeModal, setShowClaimBridgeModal] = useState(false);
  const [showCloseBridgeModal, setShowCloseBridgeModal] = useState(false);
  const [balance, setBalance] = useState(0);
  const [gblBalance, setGblBalance] = useState(0);
  const [fromNetworkFilter, setFromNetworkFilter] = useState(fromNetworkId);
const [toNetworkFilter, setToNetworkFilter] = useState(networkId);

const [showToast, setShowToast] = useState(false);
const [toastUrl, setToastUrl] = useState(null);
const [toastError, setToastError] = useState(false);
const [toastMessage, setToastMessage] = useState("-");



const displayToast = (msg, url = null,  error = false) => {
  setToastMessage(msg);
  setToastUrl(url);
  setToastError(error);
  setShowToast(true);
};

const back = () => {
  backFunction();
};

const handleCloseBridgeModal = () => {
  setBridgeAmount('');
  setReceiveAmount('');
  setShowBridgeModal(false);
};

const handleCloseClaimBridgeModal = () => {

  setShowClaimBridgeModal(false);
};


const handleShowClaimBridgeModal = async (bridge_id, amount_out, amount_in) => {
  setClaimBridgeId(bridge_id);
  setClaimBridgeAmountOut(amount_out);
  setClaimBridgeAmountIn(amount_in);
  setShowClaimBridgeModal(true);

};

const handleCloseCloseBridgeModal = () => {

  setShowCloseBridgeModal(false);
};
const handleShowCloseBridgeModal = async (bridge_id) => {
  setCloseBridgeId(bridge_id);
  setShowCloseBridgeModal(true);

};


  const getBalance = async () => {
    if (!Web3.utils.isAddress(selectedAccount)) {
      alert("Please enter a valid Ethereum address.");
      return;
    }

    try {
      const balanceWei = await web3.eth.getBalance(selectedAccount);
      const balanceEth = web3.utils.fromWei(balanceWei, "ether");
      return parseFloat(balanceEth).toFixed(6);
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };


  const getBalanceERC = async (addr) => {
    if (!Web3.utils.isAddress(addr)) {
      //alert("Please enter a valid contract address.");
      return;
    }

    const tokenContract = new web3.eth.Contract(ERC20_ABI, addr);

    try {
      const balance = await tokenContract.methods
        .balanceOf(selectedAccount)
        .call();
      const decimals = await tokenContract.methods.decimals().call();
      
      const formattedBalance = balance / 10 ** decimals;
      return formattedBalance.toFixed(6);
    } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
    }
  };


  const showSetBridge = () => {
    setSelectedFromNetwork(networkId);
    setSelectedToNetwork(fromNetworkId);
    setShowBridgeModal(true);
  };

 

  const fetchClaimedBridges = async () => {
    try {
      // Assuming `selectedAccount` is available in your component's state or props
      const response = await axios.get(`${API_BASE_URL}/bridges/claimed?selectedAccount=${selectedAccount}`);
      setClaimedBridges(response.data); // Assuming you have a state variable `setBridges` to hold the fetched data

      if(response.data.length > 0){
        setTimeout(function () {
          fetchClaimedBridges();
        }, 15000);
      }
    } catch (error) {
      console.error("Error fetching claimed bridges:", error);
    }
    

  };
  const fetchFinishedBridges = async () => {
    
    try {
      // Assuming `selectedAccount` is available in your component's state or props
      const response = await axios.get(`${API_BASE_URL}/bridges/fromuser?selectedAccount=${selectedAccount}&networkId=${networkId}`);
      console.log(response.data); 
      setFinishedBridges(response.data); 
    } catch (error) {
      console.error("Error fetching claimed bridges:", error);
    }

  };
  


const fetchFilteredBridges = async () => {
    // Constructing the URL with query parameters
    let url = `${API_BASE_URL}/bridges`;
    const queryParams = [];

    if (fromNetworkFilter) {
        queryParams.push(`from_network=${fromNetworkFilter}`);
    }
    if (toNetworkFilter) {
        queryParams.push(`to_network=${toNetworkFilter}`);
    }

    if (queryParams.length) {
        url += `?${queryParams.join('&')}`;
    }

    try {
        const response = await axios.get(url);
     
       
        let brArr = [];
        for(let i = 0; i< response.data.length; i++){
          brArr.push({
            id: response.data[i].id,
            send: response.data[i].amount_in + " GBL",
            receive: response.data[i].amount_in + " GBL",
          });
        }
        setBridges(response.data);
        setBridgesTable(brArr);
    } catch (error) {
        console.error("Error fetching filtered bridges:", error);
    }
};


  



  const claimBridge = async () => {
    setIsLoading(true); // Assuming you have a loading state
  
    try {

        if(parseFloat(balance) < parseFloat(SETTINGS.bridgeFees[networkId])){
          displayToast('Balance to low to pay fee!', null, true);
          return;
        }

        if(parseFloat(gblBalance) < parseFloat(claimBridgeAmountOut)){
          displayToast('GBL balance to low!', null, true);
          return;
        }
        
  
      const tokenContract = new web3.eth.Contract(ERC20_ABI, gblAddress);
      const bridgeTokens = web3.utils.toWei(claimBridgeAmountOut, 'ether'); // Convert if your token uses a different unit
  
      // Transfer tokens to the bridge address
      const tx = await tokenContract.methods.transfer(
        SETTINGS.bridgeAddress, // The bridge address you're sending tokens to
        bridgeTokens
      ).send({
        from: selectedAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
  
      // After successful token transfer, call the backend to update the bridge status
      const response = await axios.post(`${API_BASE_URL}/claim-bridge`, {
        id: claimBridgeId, // The ID of the bridge being claimed
        to_user: selectedAccount, // Assuming the `to_user` is the account making the claim
      });
  
      if (response.status === 200) {
        const feeInWei = web3.utils.toWei(SETTINGS.bridgeFees[networkId], 'ether');
        const transactionParameters = {
          to: SETTINGS.bridgeAddress, // Required (recipient address)
          from: selectedAccount, // Required (must match user's active address)
          value: feeInWei,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null, // Amount in wei to send
        };
    
        // Send the transaction
        const txHash = await web3.eth.sendTransaction(transactionParameters);

        displayToast('Bridge claimed.', tx.transactionHash);
        await fetchFilteredBridges(); // Refresh the list of bridges after claiming
        handleCloseClaimBridgeModal();
      } else {
        console.error('Error claiming bridge:', response.data);
      }
    } catch (error) {
      console.error("Error in claiming bridge:", error);
    }
  
    setIsLoading(false); // Reset loading state
  };


  const closeBridge = async () => {
    setIsLoading(true); // Assuming you have a loading state
  
    try {

        if(parseFloat(balance) < parseFloat(SETTINGS.bridgeFees[networkId])){
          displayToast('Balance to low to pay fee!', null, true);
          return;
        }


        
  
  
      // After successful token transfer, call the backend to update the bridge status
      const response = await axios.post(`${API_BASE_URL}/cancel-bridge`, {
        id: closeBridgeId
      });
  
      if (response.status === 200) {
        const feeInWei = web3.utils.toWei(SETTINGS.bridgeFees[networkId], 'ether');
        const transactionParameters = {
          to: SETTINGS.bridgeAddress, // Required (recipient address)
          from: selectedAccount, // Required (must match user's active address)
          value: feeInWei,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null, // Amount in wei to send
        };
    
        // Send the transaction
        const txHash = await web3.eth.sendTransaction(transactionParameters);

        displayToast('Bridge closed.', null);
        await fetchFinishedBridges(); // Refresh the list of bridges after claiming
        handleCloseCloseBridgeModal();
      } else {
        console.error('Error closing bridge:', response.data);
      }
    } catch (error) {
      console.error("Error in closing bridge:", error);
    }
  
    setIsLoading(false); // Reset loading state
  };
  


  const addBridge = async () => {
    

    if(parseFloat(balance) < parseFloat(SETTINGS.bridgeFees[networkId])){
      displayToast('Balance to low to pay fee!', null, true);
      return;
    }

    if(parseFloat(gblBalance) < parseFloat(bridgeAmount)){
      displayToast('GBL balance to low!', null, true);
      return;
    }
    if(parseFloat(bridgeAmount) <= 0){
      displayToast('Amount to low', null, true);
      return;
    }
    if(parseFloat(receiveAmount) <= 0){
      displayToast('Amount to low', null, true);
      return;
    }
    if(receiveAmount == ""){
      displayToast('Amount empty', null, true);
      return;
    }
    
    setIsLoading(true);
    try {

        if(parseInt(selectedFromNetwork) != networkId){
            await changeNetwork(parseInt(selectedFromNetwork));
            await connectMetaMask();
        }

        const tokenContract = new web3.eth.Contract(ERC20_ABI, gblAddress);
        const bridgeTokens = web3.utils.toWei(bridgeAmount, 'ether'); // Convert if your token uses a different unit
        console.log(bridgeAmount);
        try {
        // Transfer tokens
        const tx = await tokenContract.methods.transfer(
          SETTINGS.bridgeAddress, // The address you're sending tokens to
          bridgeTokens
        ).send({ 
            from: selectedAccount,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
          });

          await axios.post(`${API_BASE_URL}/bridge`, {
            from_network: parseInt(selectedFromNetwork),
            to_network: parseInt(selectedToNetwork),
            amount_in: bridgeAmount,
            amount_out: receiveAmount, // You might want to calculate this differently
            from_user: selectedAccount, // Update according to your requirements
            to_user: "", // Update according to your requirements
            status: 0, // You might want to set initial status dynamically
            headers: {
                'Content-Type': 'application/json'
              }
          });

          try {
            const feeInWei = web3.utils.toWei(SETTINGS.bridgeFees[networkId], 'ether');
            const transactionParameters = {
              to: SETTINGS.bridgeAddress, // Required (recipient address)
              from: selectedAccount, // Required (must match user's active address)
              value: feeInWei,
              maxPriorityFeePerGas: null,
              maxFeePerGas: null, // Amount in wei to send
            };
        
            // Send the transaction
            const txHash = await web3.eth.sendTransaction(transactionParameters);
            displayToast("Bridge added.", tx.transactionHash);
              } catch (error) {
                console.error("Fee failed:", error);
              }
        } catch (error) {
          console.error("Sending tokens failed:", error);
          displayToast("Failed to send tokens.", null, true);
        }
     
      

      await fetchFilteredBridges(); // Refresh the list of bridges after adding
      handleCloseBridgeModal();
     
    } catch (error) {
      console.error("Error adding bridge:", error);
    }
    setIsLoading(false);

  };

  const handleBridge = async () => {
    addBridge();
  };

  const loadBalances = async (gbl_addr) => {
           
      
    setGblBalance(await getBalanceERC(gbl_addr));
    setBalance(await getBalance());
    

};

  useEffect(() => {
    if (selectedAccount) {
        let gblAddr = SETTINGS.globalTokenAddress;
        if(networkId==1){
            gblAddr = SETTINGS.globalTokenAddressEthereum;
          }
          if(networkId==10){
            gblAddr = SETTINGS.globalTokenAddressOptimism;
          }
          
          
          setGblAddress(gblAddr);
        loadBalances(gblAddr);
        fetchClaimedBridges();
        fetchFinishedBridges();
        fetchFilteredBridges();
     
    }
  }, [selectedAccount]);







  

  return (
    <>

    <div className="boxTitle" style={{position:"relative"}}>
        <button className="backButton" onClick={back} style={{position:"relative"}}>
          <i><FontAwesomeIcon icon={faArrowLeft} /></i>
         
            </button> Bridge GBL 
            
            <span className="bridgeInfo">
    <InfoModal link="https://official.waweswaps.com/bridge-gbl" message="Bridge GBL serves as a crucial token bridge, enabling the seamless transfer of GBL tokens across various blockchain networks, including BNB Smart Chain, Polygon, Arbitrum, and Optimism. 
" />
</span>
         
            </div>
       
    <ToastNotification show={showToast} onClose={() => setShowToast(false)} message={toastMessage} url={toastUrl} error={toastError} />

      <div className="row">
        <div className="col-5" >
        <img src={networkIcons[networkId]} style={{width:20}} /><br />
        <small className="smallTitleSmall">{ networkNames[networkId]}</small>
        </div>
        <div className="col-2" >
        <i><FontAwesomeIcon icon={faArrowRight} style={{fontSize:23, marginTop:7, color:"var(--maincolor)"}} /></i>
          </div>
        <div className="col-5" >
        <img src={networkIcons[fromNetworkId]} style={{width:20}}  /><br />
        <small className="smallTitleSmall">{ networkNames[fromNetworkId]}</small>
        </div>
        <div className="col-12" >
      <br />
           
        <div id="list">
       
        
           <div className="row">
        
          {claimedBridges.map((bridge) => (
          
                <div className="col-12 col-lg-12" key={bridge.id}>
                <div className="bridgeBox" >
                <div className="row">
                   
                   <div className="col-1">
                   <img src={networkIcons[bridge.to_network]} className="walletIcon" />
                   </div>
                   <div className="col-3">
                      <small className="smallText "> 
                      Sending </small>
                      <h6> {bridge.amount_out} <small>GBL</small></h6>
                   </div>
                   <div className="col-2">
                   <i><FontAwesomeIcon icon={faArrowRightArrowLeft} /></i>
                   
                   </div>
                   <div className="col-1">
                   <img src={networkIcons[bridge.from_network]} className="walletIcon" />
                   </div>
                   <div className="col-3">
                      <small className="smallText ">
                      Receiveing</small>
                      <h6> {bridge.amount_in} <small>GBL</small></h6>
                   </div>
                 
                   <div className="col-2">
                   <Spinner animation="border" variant="primary" style={{width:20, height:20, marginTop:7, color:"var(--maincolor) !important"}} />
                   </div>
                </div>
             </div>
             </div>
          ))}
       </div>
       <button className="smallCircleButtons" style={{ float:"left", marginTop:0}} onClick={showSetBridge}>
          <i><FontAwesomeIcon icon={faPlus} /></i>
         New
            </button>
            <br />
            <br />
       {finishedBridges.length > 0 ? (<>
        <b className="smallTitle" style={{float:"left"}}>Your offers on { networkNames[networkId]}</b>
       <table className="table ">
           <thead>
    <tr>
    <th scope="col" colSpan={2}></th>
      
 
    </tr>
  </thead>
  <tbody>
          {finishedBridges.map((bridge) => (
          
            <tr  key={bridge.id} style={{padding:"10px", textAlign:"left"}}>
               <td style={{padding:"10px"}}><img src={networkIcons[bridge.from_network]} className="iconPrice" />{bridge.amount_in} <small>GBL</small></td>
            <td style={{padding:"10px", textAlign:"left"}}><img src={networkIcons[bridge.to_network]} className="iconPrice" />{bridge.amount_out} <small>GBL</small></td>
             
              <td style={{padding:"10px"}}><button className="buyButton" 
        onClick={() => {
          handleShowCloseBridgeModal(bridge.id);
        }}
      >
        Close
      </button></td>
              
         </tr>
 
          ))}
       </tbody>
        </table>
        <br />
       </>):(null)}
      
        <b className="smallTitle" style={{float:"left"}}>Offers on { networkNames[fromNetworkId]}</b>
       <SortingTable dataRows={bridges} claimFunction={handleShowClaimBridgeModal} />

           
        </div>
      
        <Modal show={showBridgeModal} onHide={handleCloseBridgeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Bridge GBL</Modal.Title>
    </Modal.Header>
    <Modal.Body style={{ paddingTop: '0px' }}>
    {isLoading ? (
                         <center><br /> <FontAwesomeIcon icon={faSpinnerThird} spin color="var(--maincolor)"  className='bigLoader' /></center>
                        ) : (<div>
    <div className='formBox'>
              <label className="form-label"> <img src={networkIcons[selectedFromNetwork]} alt="" style={{ width: '15px', marginRight: '10px' }} /> Offer GBL bridge from {networkNames[selectedFromNetwork]}</label>
<div className='row'>
  <div className='col-6 col-md-8 col-lg-8'><input
                id="tokenAmount"
                type="text"
                className="form-control"
                placeholder="0"
                value={bridgeAmount}
                onChange={(e) => setBridgeAmount(e.target.value)}
                required
              />
              <label className="form-label"><small>Balance: </small> {gblBalance}</label>
              
              </div>
  <div className='col-6 col-md-4 col-lg-4'>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect" disabled className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '20px', marginRight: '10px' }} />GBL
      </Dropdown.Toggle>

      
    </Dropdown>
  </div>
</div>

              </div>
              <div className="">
              <center>
              <i className="smallIconGray">
                    <FontAwesomeIcon icon={faLink}  />
                  </i>
              </center>
            </div>
    <div className='formBox mb-3'>
              <label className="form-label"> <img src={networkIcons[selectedToNetwork]} alt="" style={{ width: '15px', marginRight: '10px' }} /> Request GBL from {networkNames[selectedToNetwork]}</label>
<div className='row'>
  <div className='col-6 col-md-8 col-lg-8'><input
                id="tokenAmount"
                type="text"
                className="form-control"
                placeholder="0"
                value={receiveAmount}
                onChange={(e) => setReceiveAmount(e.target.value)}
                required
              />
  
              
              </div>
  <div className='col-6 col-md-4 col-lg-4'>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect" disabled className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '20px', marginRight: '10px' }} />GBL
      </Dropdown.Toggle>

      
    </Dropdown>
  </div>
</div>

              </div>
      
                  
               
          
            <div className="d-grid gap-2">
            
                            <button className="large-button-blue" type="button" onClick={handleBridge}>Bridge</button>
                            
                            <center><small>Clicking the “Bridge” button initiates a token bridging order.</small></center>
                            <center><small><img src={SETTINGS.tokenIcons[networkSymbol]} alt="" style={{ width: '20px', marginRight: '10px' }} /> Balance: {balance} {networkSymbol}</small></center>
            <center><small><img src={SETTINGS.tokenIcons[networkSymbol]} alt="" style={{ width: '20px', marginRight: '10px' }} /> Fee: {SETTINGS.bridgeFees[networkId]} {networkSymbol}</small></center>
                    </div>
                   
          </div>)}
            </Modal.Body>
  </Modal>
        <Modal show={showClaimBridgeModal} onHide={handleCloseClaimBridgeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Claim Bridge GBL</Modal.Title>
    </Modal.Header>
    <Modal.Body style={{ paddingTop: '0px' }}>
    {isLoading ? (
                         <center><br /> <FontAwesomeIcon icon={faSpinnerThird} spin color="var(--maincolor)"  className='bigLoader' /></center>
                        ) : (<div>
    <div className='formBox'>
              <label className="form-label"> <img src={networkIcons[selectedToNetwork]} alt="" style={{ width: '15px', marginRight: '10px' }} /> Bridge GBL from {networkNames[selectedToNetwork]}</label>
<div className='row'>
  <div className='col-6 col-md-8 col-lg-8'><input
                id="tokenAmount"
                type="text"
                className="form-control"
                placeholder="0"
                disabled
                value={claimBridgeAmountOut}
              />
              <label className="form-label"><small>Balance: </small> {gblBalance}</label>
              
              </div>
  <div className='col-6 col-md-4 col-lg-4'>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect" disabled className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '20px', marginRight: '10px' }} />GBL
      </Dropdown.Toggle>

      
    </Dropdown>
  </div>
</div>

              </div>
              <div className="">
              <center>
              <i className="smallIconGray">
                    <FontAwesomeIcon icon={faLink}  />
                  </i>
              </center>
            </div>
    <div className='formBox mb-3'>
              <label className="form-label"> <img src={networkIcons[selectedFromNetwork]} alt="" style={{ width: '15px', marginRight: '10px' }} /> Receive GBL from {networkNames[selectedFromNetwork]}</label>
<div className='row'>
  <div className='col-6 col-md-8 col-lg-8'><input
                id="tokenAmount"
                type="text"
                className="form-control"
                placeholder="0"
                disabled
                value={claimBridgeAmountIn}
              />
  
              
              </div>
  <div className='col-6 col-md-4 col-lg-4'>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect" disabled className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '20px', marginRight: '10px' }} />GBL
      </Dropdown.Toggle>

      
    </Dropdown>
  </div>
</div>

              </div>
      
                  
               
          
            <div className="d-grid gap-2">
            
                            <button className="large-button-blue" type="button" onClick={claimBridge}>Bridge</button>
                            <center><small>Clicking the “Bridge” button initiates a token bridging order.</small></center>
            <center><small><img src={SETTINGS.tokenIcons[networkSymbol]} alt="" style={{ width: '20px', marginRight: '10px' }} /> Balance: {balance} {networkSymbol}</small></center>
            <center><small><img src={SETTINGS.tokenIcons[networkSymbol]} alt="" style={{ width: '20px', marginRight: '10px' }} /> Fee: {SETTINGS.bridgeFees[networkId]} {networkSymbol}</small></center>
                    </div>
                   
          </div>)}
            </Modal.Body>
  </Modal>
        <Modal show={showCloseBridgeModal} onHide={handleCloseCloseBridgeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Close Bridge</Modal.Title>
    </Modal.Header>
    <Modal.Body style={{ paddingTop: '0px' }}>
    {isLoading ? (
                         <center><br /> <FontAwesomeIcon icon={faSpinnerThird} spin color="var(--maincolor)"  className='bigLoader' /></center>
                        ) : (<div>

           
      
                  
               
          
            <div className="d-grid gap-2">
            
                            <button className="large-button-blue" type="button" onClick={closeBridge}>Close</button>
            <center><small><img src={SETTINGS.tokenIcons[networkSymbol]} alt="" style={{ width: '20px', marginRight: '10px' }} /> Balance: {balance} {networkSymbol}</small></center>
            <center><small><img src={SETTINGS.tokenIcons[networkSymbol]} alt="" style={{ width: '20px', marginRight: '10px' }} /> Fee: {SETTINGS.bridgeFees[networkId]} {networkSymbol}</small></center>
                    </div>
                   
          </div>)}
            </Modal.Body>
  </Modal>


        </div>
      </div>
    
   
      
    </>
  );
};

export default Bridge;
